import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import validator from 'validator';
import Cookies from 'js-cookie';

const activeStationUid = Cookies.get('activeStation');

// Initial state
export const initialState = {
    activeStationUid: validator.isUUID(activeStationUid || '') ? activeStationUid : null,
    settings: null,
    modal: null,
    menuOpen: false,
    userHasInteractedWithScreen: false,
    videoIsPlaying: false
};

// Action types and action creators
export const actionTypes = {
    SET_ACTIVE_STATION: 'SET_ACTIVE_STATION',
    SET_SETTINGS: 'SET_SETTINGS',
    SET_MODAL: 'SET_MODAL',
    SET_MENU_OPEN: 'SET_MENU_OPEN',
    SET_USER_HAS_INTERACTED_WITH_SCREEN: 'SET_USER_HAS_INTERACTED_WITH_SCREEN',
    SET_VIDEO_IS_PLAYING: 'SET_VIDEO_IS_PLAYING'
};

export const setActiveStation = uid => ({
    type: actionTypes.SET_ACTIVE_STATION,
    uid
});

export const setSettings = settings => ({
    type: actionTypes.SET_SETTINGS,
    settings
});

export const setModal = modal => ({
    type: actionTypes.SET_MODAL,
    modal
});

export const setMenuOpen = menuOpen => ({
    type: actionTypes.SET_MENU_OPEN,
    menuOpen
});

export const setUserHasInteractedWithScreen = value => ({
    type: actionTypes.SET_USER_HAS_INTERACTED_WITH_SCREEN,
    value
});

export const setVideoIsPlaying = value => ({
    type: actionTypes.SET_VIDEO_IS_PLAYING,
    value
});

// REDUCER
export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_ACTIVE_STATION:
            if (!validator.isUUID(action.uid)) {
                return state;
            }
            Cookies.set('activeStation', action.uid, { expires: 36500 });
            return {
                ...state,
                activeStationUid: action.uid
            };
        case actionTypes.SET_SETTINGS:
            return {
                ...state,
                settings: action.settings
            };
        case actionTypes.SET_MODAL:
            return {
                ...state,
                modal: action.modal
            };
        case actionTypes.SET_MENU_OPEN:
            return {
                ...state,
                menuOpen: action.menuOpen
            };
        case actionTypes.SET_USER_HAS_INTERACTED_WITH_SCREEN:
            return {
                ...state,
                userHasInteractedWithScreen: action.value
            };
        case actionTypes.SET_VIDEO_IS_PLAYING:
            return {
                ...state,
                videoIsPlaying: action.value
            };
        default:
            return state
    }
};

/**
 * @param {object} initialState
 * @param {boolean} options.isServer indicates whether it is a server side or client side
 * @param {Request} options.req NodeJS Request object (not set when client applies initialState from server)
 * @param {Request} options.res NodeJS Request object (not set when client applies initialState from server)
 * @param {boolean} options.debug User-defined debug mode param
 * @param {string} options.storeKey This key will be used to preserve store in global namespace for safe HMR
 */
export const makeStore = (initialState, options) => {
    return createStore(
        reducer,
        initialState,
        composeWithDevTools(applyMiddleware(thunkMiddleware))
    );
};
