import React from 'react';
import BusinessAreasList from "../BusinessAreasList";
import { Transition, TransitionGroup } from "react-transition-group";

require('gsap');
const TimelineMax = window.TimelineMax;

const WelcomeScreenInfo = ({ uid, country, title, officeType, inaugurationYear, businessAreas }) => (
    <div style={{
        width: '100%',
        backgroundColor: '#002664',
        marginTop: 'auto',
        flex: '0 0 auto',
        fontSize: 56,
        lineHeight: 1.9,
        transition: 'height 0.5s ease-in-out',
        overflow: 'hidden'
    }}>
        <TransitionGroup component={null}>
            <Transition
                key={`info-${uid}`}
                timeout={1000}
                onEntering={node => {
                    new TimelineMax()
                        .fromTo(node, 1, { xPercent: -100 }, { xPercent: 0, ease: 'Quint.easeInOut' }, 0.65);
                }}
                onExiting={node => {
                    new TimelineMax()
                        .to(node, 1, { xPercent: -100, ease: 'Quint.easeInOut' });
                }}
            >
                {status => {
                    const enter = ['entering', 'entered'].indexOf(status) > -1;
                    const style = {
                        position: enter ? 'relative' : 'absolute',
                        zIndex: enter ? 1 : 2,
                        left: 0,
                        bottom: enter ? '' : 0
                    };
                    return (
                        <div style={{
                            display: 'flex',
                            width: '100%',
                            padding: '70px 80px 80px',
                            ...style
                        }}>
                            {/* Country, name, etc */}
                            <div style={{
                                width: '55%',
                                flex: '0 0 auto',
                                paddingBottom: 80
                            }}>
                                <p style={{
                                    fontWeight: 'bold',
                                    textTransform: 'uppercase'
                                }}>{country}</p>
                                <h1 style={{
                                    textTransform: 'uppercase'
                                }}>{title}</h1>
                                {officeType.length ? (
                                    <p>
                                        {officeType.map(officeType => officeType.title).join(', ')}
                                    </p>
                                ) : null}
                                {inaugurationYear ? (<p>{inaugurationYear}</p>) : null}
                            </div>
                            {/* Business areas */}
                            <div style={{
                                width: '45%',
                                flex: '0 0 auto',
                                alignSelf: 'flex-end'
                            }}>
                                <BusinessAreasList activeUids={(businessAreas || []).filter(category => category.enabled).map(category => category.uid)}/>
                            </div>
                        </div>
                    );
                }}
            </Transition>
        </TransitionGroup>
    </div>
);

export default WelcomeScreenInfo;
