import React from 'react';
import { connect } from 'react-redux';
import LazyImage from "../LazyImage";

import { setModal } from "../../store";

import { ReactComponent as PlayBtnSvg } from '../../img/btn-play.svg';

/**
 *
 * @param dispatch
 * @param className
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
const VideoJukeboxesPlug = ({ dispatch, className, ...data }) => {
    const { posterImage } = data;
    return (
        <div style={{
            display: 'block',
            width: '100%',
            height: 0,
            paddingBottom: `${(9 / 16) * 100}%`,
            position: 'relative'
        }}>
            <button className="clickable" onClick={() => dispatch(setModal({ type: 'video', data }))} style={{
                backgroundColor: '#042741',
                position: 'absolute',
                top: 0, left: 0,
                width: '100%',
                height: '100%',
                borderRadius: 5,
                overflow: 'hidden',
                boxShadow: '2px 1px 2px 0 rgba(6,22,56,0.50)'
            }}>
                {posterImage ? (
                    <LazyImage src={posterImage.url} {...posterImage} style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                    }}/>
                ) : null}
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'absolute',
                    left: 0,
                    bottom: 0,
                    backgroundColor: data.type === 'timeline' ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.75)',
                    width: '100%',
                    padding: '20px 25px 20px 20px',
                    transform: 'translate3d(0, 0, 0)'
                }}>
                    <PlayBtnSvg style={{
                        width: 48,
                        height: 48,
                        flex: '0 0 auto',
                        marginRight: 15
                    }}/>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        width: '100%',
                        flex: '1 1 auto'
                    }}>
                        <h3 dangerouslySetInnerHTML={{ __html: data.title }}
                            style={{
                                fontSize: 18,
                                lineHeight: 1.2,
                                fontWeight: 'bold',
                                textTransform: 'uppercase',
                                color: '#0D2C6D'
                            }}/>
                    </div>
                </div>
            </button>
        </div>
    );
};

export default connect()(VideoJukeboxesPlug);
