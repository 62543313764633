import React, { useEffect, useState, useRef, useCallback } from 'react';
import range from 'lodash/range';
import find from 'lodash/find';
import classNames from 'classnames';
import Flickity from 'react-flickity-component';
import { Transition, TransitionGroup } from "react-transition-group";

import TimelineItemPanel from "./TimelineItemPanel";
import ArticleTopBanner from "../ArticleTopBanner";

import styles from '../../styles/timeline.module.scss';
import ClickSwipeTip from "../ClickSwipeTip";
import TransitionScreen from "../TransitionScreen";
import { getQueryParam } from "../../helpers";

const DOT_WIDTH = 42;
const DOT_HEIGHT = 42;
const DOT_GUTTER = 5;

require('gsap');
const TimelineMax = window.TimelineMax;
const TweenMax = window.TweenMax;

const TimelineArticle = ({ status, data }) => {

    const { timeline } = data;

    const rootRef = useRef(null);
    const flickityRef = useRef(null);
    const timelineItemsRef = useRef(null);
    const itemsContainerRef = useRef(null);
    const timelineTweenRef = useRef({});

    const [timelineActive, setTimelineActive] = useState(false);
    const [activeItemUid, setActiveItemUid] = useState(getQueryParam('item'));
    const [hasScrolledTimeline, setHasScrolledTimeline] = useState(false);

    const firstDecade = Math.floor(timeline.firstYear / 10) * 10;
    const lastDecade = Math.floor(timeline.lastYear / 10) * 10;
    const decadeRange = range(firstDecade, lastDecade + 1, 10);
    const yearRange = range(firstDecade, lastDecade + 10);
    const numYears = decadeRange.length * 10;
    const timelineWidth = (numYears - 1) * (DOT_WIDTH + DOT_GUTTER);

    const positionTimeline = (x, duration, ease = 'Cubic.easeInOut', tweenProps = {}) => {
        const { current: flickity } = flickityRef;
        if (!flickity) {
            return;
        }

        if (!duration) {
            flickity.x = x;
            flickity.positionSlider();
            return;
        }

        const { current: timelineTweenObject } = timelineTweenRef;
        timelineTweenObject.x = flickity.x;
        new TweenMax(timelineTweenObject, duration, {
            x,
            ease,
            onUpdate: () => {
                flickity.x = timelineTweenObject.x;
                flickity.positionSlider();
            },
            ...tweenProps
        });
    };

    const scrollToActiveItem = useCallback((duration, ease, tweenProps) => {

        const { current: flickity } = flickityRef;
        const { current: timeline } = timelineItemsRef;
        const { current: itemsContainer } = itemsContainerRef;

        if (!activeItemUid || !itemsContainer || !timeline || !flickity) {
            return;
        }

        const activeItem = itemsContainer.querySelector(`[data-uid="${activeItemUid}"]`);

        if (!activeItem) {
            return;
        }

        const winW = window.innerWidth;
        const { left, width } = activeItem.getBoundingClientRect();
        const { left: timelineLeft } = timeline.getBoundingClientRect();

        // How much to move the slider to make the item appear in the middle of the screen
        let offsetX = ((left - timelineLeft) + (width / 2)) - (winW / 2);
        if (offsetX > flickity.slidesWidth) {
            offsetX = flickity.slidesWidth;
        } else if (offsetX < 0) {
            offsetX = 0;
        }

        positionTimeline(-offsetX, duration, ease, tweenProps);

    }, [activeItemUid, flickityRef, itemsContainerRef]);

    useEffect(() => {
        if (!activeItemUid) {
            return;
        }
        scrollToActiveItem(0.5, 'Cubic.easeInOut');
    }, [activeItemUid, flickityRef, scrollToActiveItem]);

    useEffect(() => {
        return () => {
            const { current: timelineTweenObject } = timelineTweenRef;
            if (timelineTweenObject) {
                TweenMax.killTweensOf(timelineTweenObject);
            }
        };
    }, [timelineTweenRef]);

    const displaySwipeTip = !hasScrolledTimeline && !activeItemUid && timelineActive;
    const displayClickTip = hasScrolledTimeline && !activeItemUid && timelineActive;

    return (
        <TransitionScreen status={status}>
            <div ref={rootRef} className="screen">
                {/* Top/bg graphics */}
                <ArticleTopBanner image={data.topBanner}/>
                {/* Main wrapper */}
                <div style={{
                    display: 'flex',
                    height: '100%',
                    flexDirection: 'column',
                    width: '100%',
                    position: 'relative'
                }}>
                    <div style={{
                        width: '100%',
                        height: 'calc(100% - 540px)',
                        position: 'absolute',
                        top: 540, left: 0,
                        backgroundColor: '#0D2C6D',
                        zIndex: 0
                    }}/>
                    {/* Header (title etc) */}
                    <div style={{
                        paddingLeft: '13%',
                        paddingRight: '13%',
                        position: 'relative',
                        paddingTop: 80,
                        paddingBottom: 110,
                        minHeight: 540,
                        zIndex: 1,
                        flex: '0 0 auto'
                    }}>
                        <header style={{
                            width: '100%',
                            maxWidth: 560
                        }}>
                            {data.strapline ? (
                                <p style={{
                                    fontSize: 30,
                                    lineHeight: 1.2,
                                    color: '#CDCDCD',
                                    marginBottom: 10
                                }}>{data.strapline}</p>
                            ) : null}
                            <h1 style={{
                                fontWeight: 'bold',
                                fontSize: 46,
                                lineHeight: 1.3,
                                letterSpacing: 0.56,
                                textTransform: 'uppercase',
                                marginBottom: 5
                            }} dangerouslySetInnerHTML={{ __html: data.heading }}/>
                            {data.preamble ? (
                                <div className="wysiwyg"
                                     dangerouslySetInnerHTML={{ __html: data.preamble }}
                                     style={{
                                         fontSize: 24,
                                         lineHeight: 1.5,
                                         marginBottom: 30
                                     }}/>
                            ) : null}
                        </header>
                    </div>
                    {/* Timeline wrapper */}
                    <div style={{
                        position: 'absolute',
                        left: 0,
                        top: 540,
                        width: '100%',
                        height: 'calc(100% - 540px)',
                        pointerEvents: !timelineActive ? 'none' : ''
                    }}>
                        <div style={{
                            position: 'absolute',
                            left: 0, top: -91,
                            width: '100%'
                        }}>
                            <Flickity className={styles.timeline} options={{
                                pageDots: false,
                                prevNextButtons: false,
                                wrapAround: false,
                                imagesLoaded: false,
                                contain: true,
                                cellAlign: 'left',
                                freeScroll: true,
                                on: {
                                    ready: () => {
                                        if (timelineActive) {
                                            return;
                                        }
                                        const { current: flickity } = flickityRef;
                                        const { current: root } = rootRef;
                                        if (!flickity || !root) {
                                            return;
                                        }
                                        if (activeItemUid) {
                                            // Scroll to the active item
                                            scrollToActiveItem(0);
                                            setTimelineActive(true);
                                            return;
                                        }
                                        // Do an intro animation
                                        const { width } = root.getBoundingClientRect();
                                        const { current: timelineTweenObject } = timelineTweenRef;
                                        flickity.x = width;
                                        flickity.positionSlider();
                                        timelineTweenObject.x = flickity.x;
                                        new TimelineMax({
                                            onUpdate: () => {
                                                flickity.x = timelineTweenObject.x;
                                                flickity.positionSlider();
                                            }
                                        })
                                            //.fromTo(timeline, 0.5, { opacity: 0.001 }, { opacity: 1, ease: 'Cubic.easeIn' }, 0)
                                            .to(timelineTweenObject, 1.5, { x: 0, ease: 'Quint.easeOut' }, 0.25)
                                            .add(() => {
                                                setTimelineActive(true);
                                            }, 1);
                                    },
                                    scroll: () => {
                                        const { current: flickity } = flickityRef;
                                        if (!flickity) {
                                            return;
                                        }
                                        const { x } = flickity;
                                        if (Math.abs(x) < 50) {
                                            setHasScrolledTimeline(false);
                                        } else {
                                            setHasScrolledTimeline(true);
                                        }
                                    },
                                    dragStart: () => {
                                        const { current: items } = timelineItemsRef;
                                        if (items) {
                                            items.style.pointerEvents = 'none';
                                        }
                                    },
                                    dragEnd: () => {
                                        requestAnimationFrame(() => {
                                            const { current: items } = timelineItemsRef;
                                            if (items) {
                                                items.style.pointerEvents = '';
                                            }
                                        });
                                    }
                                }
                            }} flickityRef={ref => {
                                flickityRef.current = ref;
                            }}>
                                <div ref={timelineItemsRef} className={styles.items} style={{
                                    width: timelineWidth + 240,
                                    padding: '0 120px',
                                    zIndex: 1
                                }}>
                                    <div style={{
                                        width: '100%',
                                        height: '100%',
                                        position: 'relative'
                                    }}>
                                        {/* Timeline */}
                                        <div style={{
                                            width: timelineWidth,
                                            height: '100%',
                                            position: 'absolute',
                                            top: 0,
                                            left: 0
                                        }}>
                                            <div style={{
                                                width: 'calc(100% + 6000px)',
                                                height: 3,
                                                position: 'absolute',
                                                top: 88,
                                                left: -3000,
                                                backgroundColor: '#0ABBEF'
                                            }}/>
                                            <div ref={itemsContainerRef} style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: 'calc(100% + 120px)',
                                                height: '100%'
                                            }}>
                                                {yearRange.map((year, index) => {
                                                    const isDecade = year % 10 === 0;
                                                    const item = find(timeline.items, { startYear: year });
                                                    return (
                                                        <div key={`year-${year}`}>
                                                            {isDecade ? (
                                                                <p style={{
                                                                    position: 'absolute',
                                                                    top: 15,
                                                                    left: (index * (DOT_WIDTH + DOT_GUTTER)),
                                                                    fontWeight: 'bold',
                                                                    fontSize: 22,
                                                                    lineHeight: 1.2,
                                                                    color: '#0ABBEF',
                                                                    marginLeft: -7
                                                                }}>{year}</p>
                                                            ) : null}
                                                            {year <= timeline.lastYear ? (
                                                                <div style={{
                                                                    position: 'absolute',
                                                                    left: (index * (DOT_WIDTH + DOT_GUTTER)),
                                                                    top: 52,
                                                                    width: isDecade ? 8 : 4,
                                                                    height: isDecade ? 8 : 4,
                                                                    marginLeft: isDecade ? -4 : -2,
                                                                    backgroundColor: isDecade ? '#0ABBEF' : '#fff',
                                                                    opacity: 0.5,
                                                                    borderRadius: '100%'
                                                                }}/>
                                                            ) : null}
                                                            {(() => {
                                                                if (!item) {
                                                                    return null;
                                                                }
                                                                const numYears = (item.endYear - item.startYear) + 1;
                                                                const itemWidth = numYears > 1 ? numYears * (DOT_WIDTH + DOT_GUTTER) : DOT_WIDTH;
                                                                const isActive = item.uid === activeItemUid;
                                                                return (
                                                                    <button
                                                                        data-uid={item.uid}
                                                                        className={classNames(styles.item, 'clickable', isActive ? 'is-active' : null)}
                                                                        style={{
                                                                            left: (index * (DOT_WIDTH + DOT_GUTTER)),
                                                                            top: 68,
                                                                            width: itemWidth,
                                                                            height: DOT_HEIGHT,
                                                                            marginLeft: -21
                                                                        }}
                                                                        onClick={() => setActiveItemUid(isActive ? null : item.uid)}/>
                                                                );
                                                            })()}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ position: 'absolute', width: 0, height: 0 }}/>
                            </Flickity>
                        </div>
                        <div style={{
                            position: 'absolute',
                            left: 0, top: 50,
                            width: '100%',
                            height: 'calc(100% - 50px)',
                            color: 'white',
                            overflow: 'hidden'
                        }}>
                            {/* Swipe/click tip */}
                            <Transition
                                key="swipe-tip"
                                timeout={300}
                                in={displayClickTip || displaySwipeTip}
                                mountOnEnter={true}
                                unmountOnExit={true}
                                appear={true}
                                onEntering={node => {
                                    new TimelineMax()
                                        .fromTo(node, 0.3, { opacity: 0.0001 }, {
                                            opacity: 1,
                                            ease: 'Linear.easeNone'
                                        }, 0.25)
                                        .fromTo(node, 0.5, {
                                            scaleX: 1.2,
                                            scaleY: 1.2
                                        }, { scaleX: 1, scaleY: 1, ease: 'Quint.easeOut' }, 0.25);
                                }}
                                onExiting={node => {
                                    new TimelineMax()
                                        .to(node, 0.15, { opacity: 0, ease: 'Linear.easeNone' }, 0)
                                        .to(node, 0.3, {
                                            scaleX: 1.5,
                                            scaleY: 1.5,
                                            ease: 'Cubic.easeIn'
                                        }, 0);
                                }}
                            >
                                <div style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    zIndex: 2,
                                    pointerEvents: 'none',
                                    marginTop: -30
                                }}>
                                    <ClickSwipeTip type={displayClickTip ? 'click' : 'swipe'}/>
                                </div>
                            </Transition>
                            <TransitionGroup component={null}>
                                <Transition
                                    key={activeItemUid ? `timeline-panel-${activeItemUid}` : null}
                                    appear={!!activeItemUid}
                                    timeout={300}
                                    unmountOnExit={true}
                                    onEntering={node => {
                                        if (!node) {
                                            return;
                                        }
                                        new TimelineMax()
                                            .fromTo(node, 0.5, { opacity: 0.0001 }, {
                                                opacity: 1,
                                                ease: 'Linear.easeNone'
                                            });
                                    }}
                                    onExiting={node => {
                                        if (!node) {
                                            return;
                                        }
                                        new TimelineMax()
                                            .to(node, 0.3, {
                                                opacity: 0.0001,
                                                ease: 'Linear.easeNone'
                                            });
                                    }}
                                >
                                    <TimelineItemPanel {...(find(timeline.items, { uid: activeItemUid }))}/>
                                </Transition>
                            </TransitionGroup>
                        </div>
                    </div>
                </div>
            </div>
        </TransitionScreen>
    );
};

export default TimelineArticle;
