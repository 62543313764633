import React, { useEffect, useState, useRef } from 'react';
import padStart from 'lodash/padStart';
import Moment from 'moment-timezone';

require('gsap');
const TweenMax = window.TweenMax;

const WelcomeScreenLocalTime = ({ timezone }) => {

    const [time, setTime] = useState(null);

    const hoursDial = useRef(null);
    const minutesDial = useRef(null);
    const secondsDial = useRef(null);

    useEffect(() => {
        let interval = setInterval(() => {
            const moment = new Moment().tz(timezone);
            setTime({
                hours: moment.hours(),
                minutes: moment.minutes(),
                seconds: moment.seconds()
            });
        }, 100);
        return () => {
            clearInterval(interval);
            interval = null;
        };
    }, [timezone]);

    useEffect(() => {
        if (!time) {
            return;
        }
        const { current: hoursDialPath } = hoursDial;
        const { current: minutesDialPath } = minutesDial;
        const { current: secondsDialPath } = secondsDial;
        if (!hoursDialPath || !minutesDialPath || !secondsDialPath) {
            return;
        }
        const { hours, minutes, seconds } = time;
        const hoursAngle = 360 * hours / 12 + minutes / 2;
        const minuteAngle = 360 * minutes / 60;
        const secondsAngle = 360 * seconds / 60;
        TweenMax.to(hoursDialPath, 0.5, { rotation: hoursAngle, transformOrigin: 'center center' });
        TweenMax.to(minutesDialPath, 0.5, { rotation: minuteAngle, transformOrigin: 'center center' });
        TweenMax.set(secondsDialPath, { rotation: secondsAngle, transformOrigin: 'center center' });
    }, [time]);

    if (!time) {
        return null;
    }

    return (
        <div style={{
            display: 'flex',
            alignItems: 'center'
        }}>
            <p style={{
                display: 'block',
                fontSize: 36,
                lineHeight: 1.2,
                textTransform: 'uppercase',
                textAlign: 'right',
                flex: '0 0 auto',
                marginTop: 10
            }}>
                <span>Local time</span><br/>
                <span style={{ fontWeight: 'bold' }}>{padStart(time.hours.toString(), 2, '0')}:{padStart(time.minutes.toString(), 2, '0')}</span>
            </p>
            <div style={{
                width: 130,
                height: 130,
                flex: '0 0 auto',
                marginLeft: 30
            }}>
                <svg width="130px" height="130px" viewBox="0 0 130 130" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g ref={minutesDial}>
                            <path d="M65,23 C66.1045695,23 67,23.8954305 67,25 L67,63 C67,64.1045695 66.1045695,65 65,65 C63.8954305,65 63,64.1045695 63,63 L63,25 C63,23.8954305 63.8954305,23 65,23 Z" fill="#FFFFFF"/>
                            <rect id="Rectangle" fillOpacity="0" fill="#D8D8D8" x="0" y="0" width="130" height="130"/>
                        </g>
                        <g ref={hoursDial} id="minutes">
                            <path d="M65,34 C66.6568542,34 68,35.3431458 68,37 L68,62 C68,63.6568542 66.6568542,65 65,65 C63.3431458,65 62,63.6568542 62,62 L62,37 C62,35.3431458 63.3431458,34 65,34 Z" fill="#FFFFFF"/>
                            <rect id="Rectangle" fillOpacity="0" fill="#D8D8D8" x="0" y="0" width="130" height="130"/>
                        </g>
                        <g ref={secondsDial}>
                            <rect id="Rectangle" fillOpacity="0" fill="#D8D8D8" x="0" y="0" width="130" height="130"/>
                            <path d="M65,20 C65.5522847,20 66,20.4477153 66,21 L66,64 C66,64.5522847 65.5522847,65 65,65 C64.4477153,65 64,64.5522847 64,64 L64,21 C64,20.4477153 64.4477153,20 65,20 Z" fill="#FFFFFF"/>
                        </g>
                        <circle fill="#FFFFFF" cx="65" cy="65" r="7"/>
                        <path d="M65,117.571314 C94.1016488,117.571314 117.658228,94.0147349 117.658228,65 C117.658228,35.9852651 94.1016488,12.428686 65,12.428686 C35.8983512,12.428686 12.3417722,35.9852651 12.3417722,65 C12.3417722,94.0147349 35.8983512,117.571314 65,117.571314 Z M65,130 C29.1014913,130 0,100.898509 0,65 C0,29.1014913 29.1014913,0 65,0 C100.898509,0 130,29.1014913 130,65 C130,100.898509 100.898509,130 65,130 Z" fill="#A5ACAF" fillRule="nonzero"/>
                    </g>
                </svg>
            </div>
        </div>
    );
};

export default WelcomeScreenLocalTime;
