import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import api from '../api';
import TransitionScreen from "./TransitionScreen";

const StationIndex = ({ status, activeStationUid }) => {

    const [data, setData] = useState(null);

    useEffect(() => {
        api
            .get('stations')
            .then(({ data }) => data)
            .then(({ data }) => setData(data))
            .catch(error => {
                console.error(error);
            });
    }, []);

    if (!data) {
        return null;
    }

    return (
        <TransitionScreen status={status}>
            <div className="screen flex" style={{
                flexDirection: 'column',
                padding: 40
            }}>
                <h1 style={{
                    fontSize: 24,
                    marginBottom: 20
                }}>Pick a station:</h1>
                {data.map(station => (
                    <NavLink to={`/stations/${station.uid}`} key={station.uid} style={{
                        fontSize: 36,
                        fontWeight: station.uid === activeStationUid ? 'bold' : '',
                        marginBottom: 14
                    }}>{station.title}</NavLink>
                ))}
            </div>
        </TransitionScreen>
    );
};

export default connect(state => ({
    activeStationUid: state.activeStationUid
}))(StationIndex);
