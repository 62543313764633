import React from 'react';
import { NavLink } from "react-router-dom";
import LazyImage from "../LazyImage";
import styles from "../../styles/station.module.scss";

import { COLUMN_WIDTH, GUTTER, ROW_HEIGHT } from "../Station";

const StationTimelinePlug = ({ className, ...data }) => {
    const image = data.image ? data.image.large : null;
    return (
        <NavLink to={`/articles/${data.uid}`} className={className} style={{
            width: (COLUMN_WIDTH * 2) + (GUTTER * 2),
            height: ROW_HEIGHT,
            margin: GUTTER
        }}>
            <div style={{
                backgroundColor: '#002066'
            }}>
                {image ? (
                    <LazyImage src={image.url} {...image} style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                    }}/>
                ) : null}
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    position: 'absolute',
                    left: 0,
                    bottom: 0,
                    backgroundColor: '#fff',
                    opacity: data.type === 'timeline' ? 1 : 0.75,
                    width: '100%',
                    padding: '20px 25px 20px 20px'
                }}>
                    {data.strapline ? (
                        <p className={styles.strapline} style={{
                            fontSize: 15,
                            lineHeight: 1.2,
                            marginBottom: 5
                        }}>{data.strapline}</p>
                    ) : null}
                    <h3 className={styles.heading} dangerouslySetInnerHTML={{ __html: data.title }}
                        style={{
                            fontSize: 18,
                            lineHeight: 1.2
                        }}/>
                </div>
            </div>
        </NavLink>
    );
};

export default StationTimelinePlug;
