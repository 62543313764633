import React from 'react';

const LazyImage = ({ src, width, height, style }) => (
    <img
        src={`data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 ${width} ${height}'%3E%3C/svg%3E`}
        data-src={src}
        width={width}
        height={height}
        alt=""
        className="lazyload"
        style={style}
        onDragStart={e => {
            e.preventDefault();
        }}
    />
);

export default LazyImage;
