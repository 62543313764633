import React, { useRef } from 'react';
import Scrollable from "../Scrollable";
import ArticleTopBanner from "../ArticleTopBanner";
import ArticleCardBig from "../ArticleCardBig";
import TransitionScreen from "../TransitionScreen";
import TopLink from "../TopLink";

const CollectionArticle = ({ status, data }) => {
    const containerRef = useRef(null);
    return (
        <TransitionScreen status={status}>
            <Scrollable ref={containerRef} className="screen">
                {/* Top/bg graphics */}
                <ArticleTopBanner image={data.topBanner}/>
                {/* Main wrapper */}
                <div style={{
                    display: 'flex',
                    width: '100%',
                    minHeight: '100vh',
                    paddingLeft: '13%',
                    paddingRight: '13%',
                    paddingTop: 80,
                    paddingBottom: 80,
                    position: 'relative'
                }}>
                    <div style={{
                        width: '100%',
                        height: 'calc(100% - 540px)',
                        position: 'absolute',
                        top: 540, left: 0,
                        backgroundColor: '#0D2C6D',
                        zIndex: 0
                    }}/>
                    {/* Header (title etc) */}
                    <header style={{
                        width: '38%',
                        flex: '0 0 auto',
                        position: 'relative',
                        zIndex: 1
                    }}>
                        {data.strapline ? (
                            <p style={{
                                fontSize: 30,
                                lineHeight: 1.2,
                                color: '#CDCDCD',
                                marginBottom: 10
                            }}>{data.strapline}</p>
                        ) : null}
                        <h1 style={{
                            fontWeight: 'bold',
                            fontSize: 46,
                            lineHeight: 1.3,
                            letterSpacing: 0.56,
                            textTransform: 'uppercase',
                            marginBottom: 5
                        }} dangerouslySetInnerHTML={{ __html: data.heading }}/>
                        {data.preamble ? (
                            <div className="wysiwyg" dangerouslySetInnerHTML={{ __html: data.preamble }}
                                 style={{
                                     fontSize: 24,
                                     lineHeight: 1.5,
                                     marginBottom: 30
                                 }}/>
                        ) : null}
                    </header>
                    {/* Main content */}
                    <div style={{
                        width: '100%',
                        maxWidth: 685 + 40,
                        marginLeft: 'auto',
                        position: 'relative',
                        paddingLeft: 40,
                        zIndex: 1
                    }}>
                        <ul>
                            {data.articles.map(article => (
                                <li key={article.uid} style={{ marginBottom: 26 }}>
                                    <ArticleCardBig {...article}/>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <TopLink target={containerRef}/>
            </Scrollable>
        </TransitionScreen>
    );
};

export default CollectionArticle;
