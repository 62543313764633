import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import classNames from 'classnames';

import styles from '../styles/businessarealist.module.scss';

const BusinessAreasList = ({ businessAreas, activeUids, size = 'default' }) => {

    if (!businessAreas || !businessAreas.length) {
        return null;
    }

    return (
        <ul className={styles.root} style={{
            display: 'flex',
            flexDirection: 'column',
            fontSize: size === 'small' ? 20 : 56,
            lineHeight: 1
        }}>
            {(businessAreas || []).map((businessArea, index) => (
                <li key={businessArea.uid} className={classNames(styles.item, (activeUids || []).indexOf(businessArea.uid) === -1 ? 'is-inactive' : null)} style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: index < businessAreas.length - 1 ? (size === 'small' ? 8 : 20) : 0
                }}>
                    <span style={{
                        width: size === 'small' ? 25 : 82,
                        height: size === 'small' ? 25 : 82,
                        marginRight: size === 'small' ? 20 : 80,
                        flex: '0 0 auto'
                    }} dangerouslySetInnerHTML={{ __html: businessArea.icon || '' }}/>
                    <span>{businessArea.title}</span>
                </li>
            ))}
        </ul>
    );
};

export default connect(state => ({
    businessAreas: get(state, 'settings.businessAreas', []),
}))(BusinessAreasList);
