import React from 'react';
import { withRouter } from 'react-router-dom';

import { ReactComponent as ArrowSvg } from '../img/btn-back.svg';

const BackButton = ({ history }) => (
    <button onClick={() => history.goBack()} className="clickable" style={{
        position: 'fixed',
        width: 80,
        height: 80,
        top: 40,
        left: 40,
        zIndex: 101
    }}>
        <ArrowSvg style={{
            width: 25,
            height: 43,
            position: 'absolute',
            top: '50%',
            left: '50%',
            margin: `-20px 0 0 -13px`
        }}/>
    </button>
);

export default withRouter(BackButton);
