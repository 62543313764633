import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import get from "lodash/get";

import api, { getPreviewToken } from "../api";

import { setActiveStation } from '../store';

import Scrollable from "./Scrollable";
import ArticleTopBanner from "./ArticleTopBanner";
import StationTimelinePlug from "./stations/StationTimelinePlug";
import StationArticlePlug from "./stations/StationArticlePlug";

import styles from '../styles/station.module.scss';
import StationMediaPlug from "./stations/StationMediaPlug";
import TransitionScreen from "./TransitionScreen";

const Packery = require('./PackeryComponent')(React);

export const COLUMN_WIDTH = 258;
export const ROW_HEIGHT = 305;
export const GUTTER = 14;

const Station = props => {

    const [data, setData] = useState(null);

    const uid = get(props, 'match.params.uid');
    const { dispatch } = props;

    const token = getPreviewToken();

    useEffect(() => {
        if (!token) {
            dispatch(setActiveStation(uid));
        }
    }, [dispatch, uid, token]);

    useEffect(() => {
        api
            .get(`stations/${uid}`, { params: { token } })
            .then(({ data }) => setData(data))
            .catch(error => {
                console.error(error);
            });
    }, [uid, token]);

    if (!data) {
        return null;
    }

    return (
        <TransitionScreen status={props.status}>
            <Scrollable className="screen">
                {/* Top/bg graphics */}
                <ArticleTopBanner image={data.topBanner}/>
                {/* Main wrapper */}
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    position: 'relative'
                }}>
                    <div style={{
                        width: '100%',
                        height: 'calc(100% - 540px)',
                        position: 'absolute',
                        top: 540, left: 0,
                        backgroundColor: '#0D2C6D',
                        zIndex: 0
                    }}/>
                    <div style={{
                        width: '100%',
                        maxWidth: 1920,
                        margin: '0 auto',
                        position: 'relative',
                        zIndex: 1
                    }}>
                        {/* Header (title etc) */}
                        <header style={{
                            width: '100%',
                            paddingLeft: '10%',
                            paddingRight: '10%',
                            paddingTop: 76,
                            marginBottom: 30,
                        }}>
                            <div>
                                {data.strapline ? (
                                    <p style={{
                                        fontSize: 30,
                                        lineHeight: 1.2,
                                        color: '#CDCDCD',
                                        marginBottom: 5
                                    }}>{data.strapline}</p>
                                ) : null}
                                <h1 style={{
                                    fontWeight: 'bold',
                                    fontSize: 68,
                                    lineHeight: 1.2,
                                    letterSpacing: 0.56,
                                    textTransform: 'uppercase',
                                    marginBottom: 15
                                }} dangerouslySetInnerHTML={{ __html: data.heading }}/>
                                {data.preamble ? (
                                    <div className="wysiwyg"
                                         dangerouslySetInnerHTML={{ __html: data.preamble }}
                                         style={{
                                             fontSize: 20,
                                             lineHeight: 1.5,
                                             marginBottom: 30,
                                             maxWidth: 920
                                         }}/>
                                ) : null}
                            </div>
                        </header>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            paddingBottom: 60,
                            position: 'relative',
                            left: -20
                        }}>
                            <div style={{
                                width: (COLUMN_WIDTH * 6) + ((GUTTER * 2) * 6),
                                flex: '0 0 auto',
                                margin: '0 auto'
                            }}>
                                <Packery
                                    options={{
                                        transitionDuration: 0
                                    }}
                                    disableImagesLoaded={true}
                                >
                                    {(data.articles || []).map((article, index) => {
                                        switch (article.type) {
                                            case 'timeline':
                                                return (
                                                    <StationTimelinePlug key={article.uid} {...article}
                                                                         className={classNames(styles.item, 'clickable')}/>
                                                );
                                            case 'video':
                                            case 'images':
                                                return (
                                                    <StationMediaPlug key={article.uid} {...article}
                                                                         className={classNames(styles.item, 'clickable')}/>
                                                );
                                            default:
                                                const isLarge = index % 8 === 0;
                                                return (
                                                    <StationArticlePlug key={article.uid}
                                                                 className={classNames(styles.item, 'clickable')}
                                                                 isLarge={isLarge} {...article} />
                                                );
                                        }
                                    })}
                                </Packery>
                            </div>
                        </div>
                    </div>
                </div>
            </Scrollable>
        </TransitionScreen>
    );
};

export default connect()(Station);
