import React, { useRef } from 'react';
import { connect } from 'react-redux';
import Scrollable from "../Scrollable";
import LazyImage from "../LazyImage";
import ArticleContentImage from "../ArticleContentImage";
import ArticleContentVideo from "../ArticleContentVideo";
import ArticleTopBanner from "../ArticleTopBanner";
import RelatedArticles from "../RelatedArticles";

import findIndex from 'lodash/findIndex';

import { setModal } from "../../store";
import TransitionScreen from "../TransitionScreen";
import TopLink from "../TopLink";

const TextArticle = ({ dispatch, status, data }) => {

    const containerRef = useRef(null);

    const imageGallery = data.blocks.filter(block => {
        if (block.type !== 'image' || !block.image) {
            return null;
        }
        return block;
    }).map(block => ({
        ...block.image,
        caption: block.caption || block.image.caption
    }));

    return (
        <TransitionScreen status={status}>
            <Scrollable ref={containerRef} className="screen">
                {/* Top/bg graphics */}
                <ArticleTopBanner image={data.topBanner}/>
                {/* Main wrapper */}
                <div style={{ width: '100%', minHeight: '100vh', margin: '0 auto', position: 'relative' }}>
                    <div style={{
                        width: '100%',
                        height: 'calc(100% - 540px)',
                        position: 'absolute',
                        top: 540, left: 0,
                        backgroundColor: '#0D2C6D',
                        zIndex: 0
                    }}/>
                    <div style={{
                        display: 'flex',
                        width: '100%',
                        paddingLeft: '6%',
                        paddingRight: '13%',
                        paddingTop: 60,
                        paddingBottom: 80
                    }}>
                        {/* Header (title etc) */}
                        <header style={{
                            width: '28%',
                            paddingTop: 165,
                            paddingRight: 40,
                            flex: '0 0 auto',
                            position: 'relative',
                            zIndex: 1
                        }}>
                            {data.strapline ? (
                                <p style={{
                                    fontSize: 30,
                                    lineHeight: 1.2,
                                    color: '#CDCDCD',
                                    marginBottom: 10
                                }}>{data.strapline}</p>
                            ) : null}
                            <h1 style={{
                                fontWeight: 'bold',
                                fontSize: 46,
                                lineHeight: 1.3,
                                letterSpacing: 0.56,
                                textTransform: 'uppercase',
                                marginBottom: 5
                            }}>{data.heading}</h1>
                            {data.preamble ? (
                                <div className="wysiwyg" dangerouslySetInnerHTML={{ __html: data.preamble }}
                                     style={{
                                         fontSize: 24,
                                         lineHeight: 1.5,
                                         marginBottom: 30
                                     }}/>
                            ) : null}
                            {data.topImage && data.topImage.credit ? (
                                <p style={{
                                    fontSize: 13,
                                    lineHeight: 1.5,
                                    fontStyle: 'italic'
                                }}>{data.topImage.credit}</p>
                            ) : null}
                        </header>
                        {/* Main content */}
                        <div style={{
                            width: '100%',
                            position: 'relative',
                            zIndex: 1
                        }}>
                            {data.topImage ? (
                                <div style={{
                                    marginBottom: 40,
                                    paddingRight: 140
                                }}>
                                    <div style={{
                                        background: `url(${data.topImage.lqip})`,
                                        backgroundSize: 'cover'
                                    }}>
                                        <LazyImage src={data.topImage.url} {...data.topImage}/>
                                    </div>
                                    {data.topImage.caption ? (
                                        <p style={{
                                            fontSize: 13,
                                            lineHeight: 1.5,
                                            fontStyle: 'italic',
                                            marginTop: 10
                                        }}>{data.topImage.caption}</p>
                                    ) : null}
                                </div>
                            ) : (
                                <div style={{
                                    height: 0,
                                    paddingTop: `${(550 / 980) * 100}%`
                                }}/>
                            )}
                            <div style={{
                                display: 'flex',
                                width: '100%'
                            }}>
                                <div style={{
                                    width: '62%',
                                    flex: '0 0 auto'
                                }}>
                                    {(data.blocks || []).map(block => {
                                        switch (block.type) {
                                            case 'text':
                                                return (block.text ? (
                                                    <div key={block.uid} className="wysiwyg"
                                                         dangerouslySetInnerHTML={{ __html: block.text }}
                                                         style={{
                                                             marginBottom: 30
                                                         }}/>
                                                ) : null);
                                            case 'image':
                                                return block.image ? (
                                                    <button className="clickable" key={block.uid} onClick={() => dispatch(setModal({
                                                        type: 'images',
                                                        startAt: findIndex(imageGallery, { uid: block.image.uid }),
                                                        data:  {
                                                            images: imageGallery
                                                        }
                                                    }))} style={{
                                                        display: 'block',
                                                        width: '100%'
                                                    }}>
                                                        <ArticleContentImage {...block} />
                                                    </button>
                                                ) : null;
                                            case 'video':
                                                return block.video ? (
                                                    <button className="clickable" key={block.uid} onClick={() => dispatch(setModal({
                                                        type: 'video',
                                                        data: block
                                                    }))} style={{
                                                        display: 'block',
                                                        width: '100%'
                                                    }}>
                                                        <ArticleContentVideo {...block} />
                                                    </button>
                                                ) : null;
                                            default:
                                                return null;
                                        }
                                    })}
                                </div>
                                <aside style={{
                                    width: '100%',
                                    flex: '0 1 auto',
                                    paddingLeft: 26,
                                    marginLeft: 'auto'
                                }}>
                                    {(data.relatedArticles || []).length ? (
                                        <RelatedArticles articles={data.relatedArticles}/>
                                    ) : null}
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
                <TopLink target={containerRef}/>
            </Scrollable>
        </TransitionScreen>
    );
};

export default connect()(TextArticle);
