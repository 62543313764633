import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router-dom';

import get from "lodash/get";

import styles from '../styles/contentscreens.module.scss';

import { getPreviewToken } from "../api";

import ModalMount from "./ModalMount";
import VideoJukeboxesIndex from "./video-jukeboxes/VideoJukeboxesIndex";
import VideoJukeboxesEntry from "./video-jukeboxes/VideoJukeboxesEntry";

export const SCREEN_TRANSITION_TIME = 300;

const ANTI_BURNIN_POSITIONS = [-5, 0];

const VideoJukeboxesScreen = ({ location, history, hasModal, antiBurnInInterval }) => {

    // Anti burn-in timeout
    const [position, setPosition] = useState(0);
    useEffect(() => {
        if (!antiBurnInInterval) {
            return;
        }
        let timeout;
        const resetTimeout = () => {
            if (timeout) {
                clearTimeout(timeout);
            }
            timeout = setTimeout(() => {
                const newPosition = position < ANTI_BURNIN_POSITIONS.length - 1 ? position + 1 : 0;
                setPosition(newPosition);
                resetTimeout();
            }, antiBurnInInterval);
        };
        // Reset timeout on navigation
        const historyListener = history.listen(() => {
            resetTimeout();
        });
        resetTimeout();
        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
            historyListener();
        };
    }, [antiBurnInInterval, hasModal, history, position]);

    // Scale screen
    const [scale, setScale] = useState(1);

    const resizeHandler = useCallback(() => {
        // Only scale for Live Preview
        const token = getPreviewToken();
        if (!token) {
            setScale(1);
            return;
        }
        const winWidth = window.innerWidth;
        if (winWidth < 1920) {
            setScale(winWidth / 1920);
        } else {
            setScale(1);
        }
    }, []);

    useEffect(() => {
        window.addEventListener('resize', resizeHandler);
        resizeHandler();
        return () => {
            window.removeEventListener('resize', resizeHandler);
        };
    }, [resizeHandler]);

    return (
        <>
            <div className={styles.root} style={{
                transform: `translate(${ANTI_BURNIN_POSITIONS[position]}px,${ANTI_BURNIN_POSITIONS[position]}px) scale(${scale}, ${scale})`,
                transformOrigin: 'left top'
            }}>
                <Switch location={location}>
                    <Route exact path="/video-jukeboxes" render={props => <VideoJukeboxesIndex {...props}/>}/>
                    <Route exact path="/video-jukeboxes/:uid" render={props => <VideoJukeboxesEntry {...props}/>}/>
                </Switch>
            </div>
            <ModalMount/>
        </>
    );
};

export default withRouter(connect(state => ({
    timeoutInterval: get(state, 'settings.contentScreenTimeout', null),
    antiBurnInInterval: get(state, 'settings.stationAntiBurnInInterval', null),
    hasModal: !!state.modal,
    videoIsPlaying: state.videoIsPlaying
}))(VideoJukeboxesScreen));
