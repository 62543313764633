import React, { useEffect, useState, useCallback, useRef } from 'react';
import { ReactComponent as ScrollUpArrow } from "../img/btn-back.svg";
import { ReactComponent as HandSvg } from "../img/scroll-hand.svg";
import { ReactComponent as ScrollDownArrow } from "../img/scroll-arrow.svg";

require('gsap');
require('gsap/ScrollToPlugin');
const TweenMax = window.TweenMax;
const TimelineMax = window.TimelineMax;

const TopLink = ({ target }) => {

    const handRef = useRef(null);
    const arrowLeftRef = useRef(null);
    const arrowRightRef = useRef(null);

    const [scrollUpButtonVisible, setScrollUpButtonVisible] = useState(false);
    const [isScrollable, setIsScrollable] = useState(false);

    const onTargetScroll = useCallback(e => {
        setScrollUpButtonVisible((e.target.scrollTop >= (window.innerHeight * 0.15)));
    }, [setScrollUpButtonVisible]);

    const onResize = useCallback(() => {
        const { current: el } = target;
        if (!el) {
            return;
        }
        setIsScrollable((el.scrollHeight - 10) > window.innerHeight);
    }, [target, setIsScrollable]);

    const onScrollUpBtnClick = () => {
        const { current: el } = target;
        if (!el) {
            return;
        }
        TweenMax.to(el, 1, {
            scrollTo: 0,
            ease: 'Quint.easeInOut'
        });
    };

    useEffect(() => {
        let timeline;
        const { current: hand } = handRef || {};
        const { current: arrowLeft } = arrowLeftRef || {};
        const { current: arrowRight } = arrowRightRef || {};
        if (isScrollable && !scrollUpButtonVisible && hand && arrowLeft && arrowRight) {
            timeline = new TimelineMax({
                delay: 1.5,
                repeat: -1
            })
                .set([arrowLeft, arrowRight, hand], { y: 0 })
                .to(arrowLeft, 0.5, { y: 6, ease: 'Back.easeInOut', repeat: 3, yoyo: true }, 'arrows')
                .to(arrowRight, 0.5, { y: -6, ease: 'Back.easeInOut', repeat: 3, yoyo: true }, 'arrows')
                .fromTo(hand, 0.5, { y: 0 }, { y: -20, ease: 'Cubic.easeInOut', repeat: 3, yoyo: true }, 'hand-=0.35')
                .add(() => {}, 'end+=2');
        }
        return () => {
            if (timeline) {
                timeline.kill();
            }
            if (hand && arrowLeft && arrowRight) {
                TweenMax.set([hand, arrowLeft, arrowRight], { clearProps: 'y' });
            }
        };
    }, [isScrollable, scrollUpButtonVisible]);

    useEffect(() => {
        const { current: el } = target;
        if (!el) {
            return;
        }
        el.addEventListener('scroll', onTargetScroll);
        return () => {
            el.removeEventListener('scroll', onTargetScroll);
        };
    }, [target, onTargetScroll]);

    useEffect(() => {
        window.addEventListener('resize', onResize);
        onResize();
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [onResize]);

    if (!target.current) {
        return null;
    }

    if (!isScrollable) {
        return null;
    }

    return (
        <>
            <div style={{
                position: 'fixed',
                right: 50,
                bottom: 50,
                zIndex: 99,
                width: 64,
                height: 91,
                opacity: scrollUpButtonVisible ? 0 : 1,
                pointerEvents: scrollUpButtonVisible ? 'none' : 'auto',
                transition: 'opacity 0.3s, transform 0.5s',
                transform: scrollUpButtonVisible ? 'translate(0, 100px)' : 'translate(0, 0)'
            }}>
                <div ref={handRef} style={{
                    position: 'absolute',
                    right: -3,
                    bottom: 0,
                    zIndex: 2
                }}>
                    <HandSvg style={{
                        width: 61,
                        height: 81
                    }}/>
                </div>
                <div ref={arrowRightRef} style={{
                    position: 'absolute',
                    left: 10,
                    top: 0,
                    zIndex: 1
                }}>
                    <ScrollDownArrow style={{
                        width: 13,
                        height: 32
                    }}/>
                </div>
                <div ref={arrowLeftRef} style={{
                    position: 'absolute',
                    left: 0,
                    top: 14,
                    zIndex: 1
                }}>
                    <ScrollDownArrow style={{
                        width: 13,
                        height: 32,
                        transform: 'scale(1, -1)'
                    }}/>
                </div>
            </div>
            <button onClick={onScrollUpBtnClick} className="clickable" style={{
                position: 'fixed',
                right: 70,
                bottom: 50,
                zIndex: 99,
                opacity: scrollUpButtonVisible ? 1 : 0,
                pointerEvents: scrollUpButtonVisible ? 'auto' : 'none',
                transition: 'opacity 0.3s, transform 0.5s',
                transform: scrollUpButtonVisible ? 'translate(0, 0)' : 'translate(0, 100px)',
                width: 80,
                height: 80
            }}>
                <ScrollUpArrow style={{
                    width: 25,
                    height: 43,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    margin: '-21px 0 0 -12px',
                    transform: 'rotate(90deg)'
                }}/>
            </button>
        </>
    );
};

export default TopLink;
