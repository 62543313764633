import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import validator from 'validator';

const Index = ({ activeStationUid }) => (
    <Redirect to={`/stations/${validator.isUUID(activeStationUid || '') ? activeStationUid : ''}`}/>
);

export default connect(state => ({
    activeStationUid: state.activeStationUid
}))(Index);
