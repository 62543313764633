import React, { useEffect, useState, useCallback } from 'react';
import { Transition, TransitionGroup } from "react-transition-group";
import { xml2json } from 'xml-js';

import get from 'lodash/get';
import axios from 'axios';

const WEATHER_SERVICE = 'https://api.met.no/weatherapi/locationforecast/2.0/classic/'; // ?lat=60.10&lon=9.58
const CACHE_FOR_SECONDS = 60 * 60; // 1 hour

const cloudSvg = require('../../img/weather-icons/cloud.svg');
const lightCloudSvg = require('../../img/weather-icons/light-cloud.svg');
const lightRainSvg = require('../../img/weather-icons/light-rain.svg');
const lightSnowSvg = require('../../img/weather-icons/light-snow.svg');
const rainSvg = require('../../img/weather-icons/rain.svg');
const snowSvg = require('../../img/weather-icons/snow.svg');
const sunSvg = require('../../img/weather-icons/sun.svg');

require('gsap');
const TimelineMax = window.TimelineMax;

// Map SVG graphics to api.met.no weather icons
const WEATHER_ICONS = [{
    svg: lightCloudSvg,
    icons: ['LightCloud', 'PartlyCloud']
}, {
    svg: cloudSvg,
    icons: ['Cloud', 'Fog']
}, {
    svg: lightRainSvg,
    icons: ['LightRainSun', 'LightRainThunderSun', 'LightRain', 'LightRainThunder', 'DrizzleThunderSun', 'DrizzleThunder', 'Drizzle']
}, {
    svg: rainSvg,
    icons: ['Rain', 'RainThunder', 'SleetSunThunder', 'RainThunderSun', 'HeavySleetThunderSun']
}, {
    svg: lightSnowSvg,
    icons: ['SnowSun', 'LightSnowThunderSun', 'LightSleetThunder', 'LightSleetThunderSun', 'Sleet', 'SleetSun', 'LightSnowThunder', 'LightSleetSun', 'LightSnowSun', 'LightSleet', 'LightSnow']
}, {
    svg: snowSvg,
    icons: ['Snow', 'SnowThunder', 'SnowSunThunder', 'HeavySnowThunderSun', 'HeavySleetThunder', 'SleetThunder', 'HeavySnowThunder', 'HeavySleetSun', 'HeavySnowSun', 'HeavysnowSun', 'HeavySleet', 'HeavySnow']
}, {
    svg: sunSvg,
    icons: ['Sun', 'DrizzleSun', 'RainSun']
}];

const STORAGE_KEY_TEMPLATE = 'weather.2.0-classic:{latlng}';

const getStorageKey = (lat, lng) => STORAGE_KEY_TEMPLATE.replace('{latlng}', `${lat},${lng}`);

const WelcomeScreenWeather = ({ uid, lat: latFromProps, lng: lngFromProps }) => {

    const [latLng, setLatLng] = useState(null);
    const [currentWeather, setCurrentWeather] = useState(null);

    const { lat, lng } = latLng || {};

    const updateWeather = useCallback((data = null) => {
        if (data) {
            // Cache it
            const now = new Date().getTime();
            const storageKey = getStorageKey(lat, lng);
            sessionStorage.setItem(storageKey, JSON.stringify({
                time: now,
                data
            }));
        }
        setCurrentWeather(data);
    }, [lat, lng]);

    const updateWeatherFromXml = useCallback(xml => {
        const data = JSON.parse(xml2json(xml, { compact: true, spaces: 4 }));
        if (!data) {
            console.warn('Unable to parse XML', { data })
        }
        let icon = null;
        const forecasts = get(data, 'weatherdata.product.time', []);
        for (let i = 0; i < forecasts.length; ++i) {
            icon = get(forecasts[i], 'location.symbol._attributes', null);
            if (icon) {
                break;
            }
        }
        if (!icon) {
            return null;
        }
        const { id: iconId } = icon;
        updateWeather(iconId);
    }, [updateWeather]);

    const getWeatherSvgKeyFromCurrentWeather = useCallback(weather => {
        for (let i = 0; i < WEATHER_ICONS.length; ++i) {
            if (WEATHER_ICONS[i].icons.indexOf(weather) > -1) {
                return WEATHER_ICONS[i].svg;
            }
        }
        return null;
    }, []);

    useEffect(() => {
        setLatLng({
            lat: latFromProps,
            lng: lngFromProps
        });
    }, [latFromProps, lngFromProps]);

    useEffect(() => {

        if (!lat || !lng) {
            return;
        }

        let cancelTokenSource;

        const storageKey = getStorageKey(lat, lng);
        const cachedData = JSON.parse(sessionStorage.getItem(storageKey));

        if (cachedData && cachedData.data && new Date().getTime() - cachedData.time < CACHE_FOR_SECONDS * 1000) {
            console.info('Got weather from cache');
            updateWeather(cachedData.data);
        } else {
            cancelTokenSource = axios.CancelToken.source();
            axios
                .get(`${WEATHER_SERVICE}?lat=${lat}&lon=${lng}`, {
                    cancelToken: cancelTokenSource.token
                })
                .then(({ status, data: xml }) => {
                    if (status === 200 && xml) {
                        console.info('Got weather data from API');
                        updateWeatherFromXml(xml);
                    } else {
                        console.warn('Unable to fetch XML', { status, xml });
                    }
                })
                .catch(error => {
                    if (axios.isCancel(error)) {
                        return;
                    }
                    console.error(error);
                })
                .then(() => {
                    cancelTokenSource = null;
                });
        }
        return () => {
            if (cancelTokenSource) {
                cancelTokenSource.cancel();
            }
        };
    }, [lat, lng, updateWeather, updateWeatherFromXml]);

    if (!currentWeather) {
        return null;
    }

    const svg = getWeatherSvgKeyFromCurrentWeather(currentWeather);

    return (
        <div style={{
            width: 100,
            height: 150,
            position: 'relative',
            margin: '0 auto',
            overflow: 'hidden'
        }}>
            <TransitionGroup component={null}>
                <Transition
                    key={`weather-icon-${uid}`}
                    timeout={500}
                    in={!!(svg && currentWeather)}
                    appear={true}
                    onEntering={node => {
                        new TimelineMax({
                            delay: 0.25
                        })
                            .fromTo(node, 0.75, { yPercent: -200 }, { yPercent: 0, ease: 'Quint.easeOut' }, 0.5)
                            .fromTo(node, 0.3, { opacity: 0.001 }, { opacity: 1, ease: 'Cubic.easeIn' }, 0.5);
                    }}
                    onExiting={node => {
                        new TimelineMax()
                            .to(node, 0.5, { yPercent: -200, ease: 'Quint.easeIn' }, 0)
                            .to(node, 0.3, { opacity: 0.001, ease: 'Cubic.easeIn' }, 0.2);
                    }}
                >
                    <div style={{
                        width: '100%',
                        height: 100,
                        position: 'absolute',
                        left: 0,
                        bottom: 0
                    }}>
                        {svg ? (
                            <img src={svg} style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }} alt="" />
                        ) : null}
                    </div>
                </Transition>
            </TransitionGroup>
        </div>
    );
};

export default WelcomeScreenWeather;
