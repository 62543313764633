import React from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { setModal } from "../store";
import LazyImage from "./LazyImage";

import { ReactComponent as PlayBtnSvg } from '../img/btn-play.svg';

import styles from '../styles/articlecard.module.scss';
import { truncateText } from "../helpers";

const ArticleCardContent = ({ type, title, listingTitle, strapline, listingImage }) => (
    <div className={styles.root}>
        {listingImage || (type === 'images' || type === 'video') ? (
            <div style={{
                width: 116,
                position: 'relative',
                overflow: 'hidden',
                flex: '0 0 auto',
                backgroundColor: '#002066',
                backgroundImage: listingImage ? `url(${listingImage.lqip})` : null,
                backgroundSize: 'cover'
            }}>
                {type === 'video' ? (
                    <PlayBtnSvg style={{
                        position: 'relative',
                        left: '50%',
                        top: '50%',
                        width: 60,
                        height: 60,
                        margin: '-30px 0 0 -30px',
                        zIndex: 2
                    }}/>
                ) : null}
                {listingImage ? (
                    <LazyImage src={listingImage.url} {...listingImage} style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        top: 0,
                        left: 0,
                        objectFit: 'cover',
                        zIndex: 1
                    }}/>
                ) : null}
            </div>
        ) : null}
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            flex: '1 1 auto',
            padding: '15px 18px 18px 18px',
            overflow: 'hidden'
        }}>
            {strapline ? (
                <p style={{
                    display: 'block',
                    width: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    color: '#7E7E7E',
                    fontSize: 15,
                    lineHeight: 1.2,
                    textTransform: 'uppercase',
                    marginBottom: 5,
                    whiteSpace: 'nowrap'
                }}>{strapline}</p>
            ) : null}
            <h3 style={{
                fontSize: 18,
                lineHeight: 1.1,
                fontWeight: 'bold',
                textTransform: 'uppercase',
                color: '#0D2C6D'
            }}>
                {truncateText(listingTitle || title, 45)}
            </h3>
        </div>
    </div>
);

const ArticleCard = ({ dispatch, style = {}, asModal = false, ...props }) => {

    const { type, section, uid } = props;

    return (
        <div style={{ width: '100%', display: 'flex' }}>
            {(() => {
                if (asModal || (type === 'video' || type === 'images')) {
                    return (
                        <a href={`/${section}/${uid}`} className="clickable" onClick={e => {
                            e.preventDefault();
                            dispatch(setModal({
                                type,
                                uid
                            }));
                        }} style={{ width: '100%', display: 'flex' }}>
                            <ArticleCardContent type={type} {...props}/>
                        </a>
                    );
                }

                return (
                    <Link to={`/${section}/${uid}`} className="clickable" style={{ width: '100%', display: 'flex' }}>
                        <ArticleCardContent type={type} {...props}/>
                    </Link>
                );
            })()}
        </div>
    );

};

export default connect()(ArticleCard);
