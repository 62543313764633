import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import get from 'lodash/get';

import api, { getPreviewToken } from '../api';
import TextArticle from "./articles/TextArticle";
import CollectionArticle from "./articles/CollectionArticle";
import TimelineArticle from "./timeline/TimelineArticle";
import OfficeListing from "./offices/OfficeListing";

import Images from "./Images";
import Video from "./Video";

const Article = props => {

    const [data, setData] = useState(props.data || null);

    const uid = props.uid || get(props, 'match.params.uid');

    useEffect(() => {
        if (data) {
            return;
        }
        api
            .get(`articles/${uid}`, { params: { token: getPreviewToken() } })
            .then(({ data }) => setData(data))
            .catch(error => {
                console.error(error);
            });
    }, [uid, data]);

    if (!data) {
        return null;
    }

    const { type } = data;

    if (type === 'container') {
        // Shouldn't really ever end up on a container article entry, but if we do – redirect to the first descendant
        const firstDescendant = (data.descendants || [])[0] || null;
        if (firstDescendant) {
            return (
                <Redirect to={`/articles/${firstDescendant.uid}`}/>
            );
        }
        return null;
    }

    if (type === 'article') {
        return (
            <TextArticle status={props.status} data={data}/>
        );
    }

    if (type === 'collection') {
        return (
            <CollectionArticle status={props.status} data={data}/>
        );
    }

    if (type === 'timeline') {
        return (
            <TimelineArticle status={props.status} data={data}/>
        );
    }

    if (type === 'offices') {
        return (
            <OfficeListing status={props.status} data={data}/>
        );
    }

    if (type === 'images') {
        return (
            <Images status={props.status} data={data}/>
        );
    }

    if (type === 'video') {
        return (
            <Video status={props.status} data={data}/>
        );
    }

    return (
        <div>{type}</div>
    );
};

export default Article;
