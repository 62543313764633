import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import api from '../../api';

import { setUserHasInteractedWithScreen } from "../../store";

/**
 *
 * @param dispatch
 * @returns {JSX.Element|null}
 * @constructor
 */
const VideoJukeboxesIndex = ({ dispatch }) => {

    const [data, setData] = useState(null);

    useEffect(() => {
        api
            .get('video-jukeboxes')
            .then(({ data }) => data)
            .then(({ data }) => setData(data))
            .catch(error => {
                console.error(error);
            });
    }, []);

    if (!data) {
        return null;
    }

    return (
        <div className="screen flex" style={{
            flexDirection: 'column',
            padding: 40
        }} onClick={() => dispatch(setUserHasInteractedWithScreen(true))}>
            <h1 style={{
                fontSize: 24,
                marginBottom: 20
            }}>Pick a video jukebox:</h1>
            {data.map(jukebox => (
                <NavLink to={`/video-jukeboxes/${jukebox.uid}`} key={jukebox.uid} style={{
                    fontSize: 36,
                    fontWeight: 'bold',
                    marginBottom: 14
                }}>{jukebox.title}</NavLink>
            ))}
        </div>
    );
};

export default connect()(VideoJukeboxesIndex);
