import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import ArticleTopBanner from "../ArticleTopBanner";
import Scrollable from "../Scrollable";

import api from '../../api';
import { getQueryParam } from "../../helpers";

import TopLink from '../TopLink';
import LazyImage from "../LazyImage";
import TransitionScreen from "../TransitionScreen";
import BusinessAreasList from "../BusinessAreasList";

import styles from '../../styles/officelisting.module.scss';

import mapPng from '../../img/map-offices.png';
import miniMapPng from '../../img/minimap.png';

require('gsap');
require('gsap/ScrollToPlugin');
const TweenMax = window.TweenMax;

const AREA_BTN_POSITIONS = {
    am: 343,
    sca: 486,
    we: 628,
    eeca: 769,
    meia: 910,
    seap: 1052,
    nea: 1197
};

const AREA_BTN_DIAMETER = 116;

const OfficeListing = ({ status, data }) => {

    const activeAreaInUrl = getQueryParam('area');
    const containerRef = useRef(null);
    const navRef = useRef(null);
    const areasRef = useRef(null);
    const areaButtonsRef = useRef(null);

    const [hasInitScrolled, setHasInitScrolled] = useState(false);
    const [areas, setAreas] = useState(null);
    const [navIsFixed, setNavIsFixed] = useState(false);
    const [buttonAnimationPaused, setButtonAnimationPaused] = useState(false);
    const [activeArea, setActiveArea] = useState(activeAreaInUrl);

    useEffect(() => {
        api
            .get('areas')
            .then(({ data }) => data)
            .then(({ data }) => setAreas(data))
            .catch(error => {
                console.error(error);
            });
    }, []);

    useEffect(() => {
        let queryString = '';
        if (activeArea) {
            queryString = `?area=${activeArea}`;
        }
        const { pathname } = window.location;
        const url = `${pathname}${queryString}`;
        window.history.replaceState(null, null, url);
    }, [activeArea]);

    useEffect(() => {
        if (hasInitScrolled || !activeAreaInUrl || !areas) {
            return;
        }
        setHasInitScrolled(true);
        const index = findIndex(areas || [], { uid: activeAreaInUrl });
        scrollToArea(index, 0);
    }, [hasInitScrolled, activeAreaInUrl, areas]);

    if (areas === null) {
        return null;
    }

    let officeAreas = (areas || []).filter(area => !!area.code && Object.keys(AREA_BTN_POSITIONS).indexOf(area.code.toLowerCase()) > -1);
    if (officeAreas.length > 7) {
        officeAreas.length = 7;
    }

    // Make sure the areas are sorted according to the sort order defined by the map gfx
    officeAreas = Object.keys(AREA_BTN_POSITIONS).map(code => find(areas, { code }));

    const onContainerScroll = e => {
        // Should the nav be fixed?
        const navEl = navRef.current;
        if (navEl) {
            const navRect = navEl.getBoundingClientRect();
            const { top } = navRect;
            if (top <= 0) {
                setNavIsFixed(true);
            } else {
                setNavIsFixed(false);
            }
        }
        // Do we have an active area?
        const scrollTop = e.target.scrollTop;
        setButtonAnimationPaused(scrollTop > 0);
        if (scrollTop < 150 || !officeAreas.length) {
            setActiveArea(null);
            return;
        }
        const areasEl = areasRef.current;
        const areaEls = areasEl ? Array.from(areasEl.childNodes) : [];
        let activeElUid = officeAreas[0].uid;
        for (let i = 0; i < areaEls.length; ++i) {
            const rect = areaEls[i].getBoundingClientRect();
            // If this rect's lower bound is above the viewport, it's not active
            const lowerBound = rect.top + rect.height;
            if (lowerBound > window.innerHeight / 2) {
                activeElUid = officeAreas[i].uid;
                break;
            }
        }
        setActiveArea(activeElUid);
    };

    const scrollToArea = (index, duration = 1) => {
        const containerEl = containerRef.current;
        if (!containerEl) {
            return;
        }
        setHasInitScrolled(true);
        const areasEl = areasRef.current;
        const areaEls = areasEl ? Array.from(areasEl.childNodes) : [];
        const area = areaEls[index] || null;
        if (!area) {
            return;
        }
        const { height: containerHeight } = containerEl.getBoundingClientRect();
        const { top: areaTop } = area.getBoundingClientRect();
        const { top: areasTop } = areasEl.getBoundingClientRect();
        const areaOffset = (areaTop - areasTop) + containerHeight - 150;
        if (duration) {
            TweenMax.to(containerEl, 1, {
                scrollTo: areaOffset,
                ease: 'Quint.easeInOut'
            });
        } else {
            TweenMax.set(containerEl, {
                scrollTo: areaOffset
            });
        }
    };

    const scrollToTop = () => {
        const containerEl = containerRef.current;
        if (!containerEl) {
            return;
        }
        TweenMax.to(containerEl, 1, {
            scrollTo: 0,
            ease: 'Quint.easeInOut'
        });
    };

    return (
        <TransitionScreen status={status}>
            <Scrollable ref={containerRef} className="screen" onScroll={onContainerScroll}>
                <nav ref={navRef} style={{
                    width: '100%',
                    height: 150,
                    position: 'absolute',
                    left: 0,
                    top: 'calc(100% - (150px + 38px))',
                    zIndex: 2
                }}>
                    <div style={{
                        width: '100%',
                        height: 150,
                        position: navIsFixed ? 'fixed' : 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 2,
                        backgroundColor: navIsFixed ? '#092762' : 'transparent',
                        transition: 'background-color 0.3s'
                    }}>
                        <div ref={areaButtonsRef} style={{
                            width: 1450,
                            height: '100%',
                            position: 'absolute',
                            top: 0, right: '3.5%'
                        }}>
                            <button className="clickable" style={{
                                display: 'block',
                                width: AREA_BTN_DIAMETER,
                                height: AREA_BTN_DIAMETER,
                                position: 'absolute',
                                left: 140,
                                top: '50%',
                                margin: `-${AREA_BTN_DIAMETER / 2}px 0 0`,
                                border: '10px solid rgba(10, 187, 239, 0.5)',
                                borderRadius: '100%',
                                opacity: !!activeArea ? 1 : 0.001,
                                pointerEvents: !!activeArea ? 'auto' : 'none',
                                transition: 'opacity 0.3s'
                            }} onClick={() => scrollToTop()}>
                                <LazyImage src={miniMapPng} style={{
                                    width: 76,
                                    height: 37,
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    margin: '-18px 0 0 -38px'
                                }}/>
                            </button>
                            {officeAreas.map((area, index) => (
                                <div key={`area-btn-${area.uid}`} className={classNames(styles.areabuttonwrapper, 'u-gpu', buttonAnimationPaused ? 'paused' : null)} data-areabtn style={{
                                    width: AREA_BTN_DIAMETER,
                                    height: AREA_BTN_DIAMETER,
                                    left: (AREA_BTN_POSITIONS[area.code] || 0) - (AREA_BTN_DIAMETER / 2),
                                    margin: `-${AREA_BTN_DIAMETER / 2}px 0 0`,
                                    animationDelay: `${0.15 * index}s`,
                                    transform: 'scale(1.001,1.001)'
                                }}>
                                    <button className={classNames(styles.areabutton, 'clickable', area.uid === activeArea ? 'is-active' : null)} style={{
                                        width: AREA_BTN_DIAMETER,
                                        height: AREA_BTN_DIAMETER,
                                        left: 0,
                                        top: 0
                                    }} onClick={() => scrollToArea(index)}>
                                        <div>
                                            <p style={{ fontSize: 24, lineHeight: 1.2, textTransform: 'uppercase', marginTop: -4, marginBottom: -2 }}>{area.code}</p>
                                            <p style={{ fontSize: 10, lineHeight: 1.2 }}>{area.title}</p>
                                        </div>
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                </nav>
                <header style={{
                    width: '100%',
                    height: '100vh',
                    position: 'relative'
                }}>
                    {/* Top/bg graphics */}
                    <ArticleTopBanner image={data.topBanner}/>
                    {/* Map */}
                    <div style={{
                        position: 'absolute',
                        top: 'calc(100vh - 150px - 818px - 20px)',
                        right: '3.5%',
                        width: 1450,
                        height: 820,
                        zIndex: 1
                    }}>
                        <LazyImage src={mapPng} style={{
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            top: 0, left: 0
                        }}/>
                    </div>
                    {/* Header/title etc */}
                    <div style={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: 0, left: 0,
                        padding: '540px 6.5% 0'
                    }}>
                        <div style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            maxWidth: 540,
                            position: 'relative',
                            zIndex: 1
                        }}>
                            {data.strapline ? (
                                <p style={{
                                    fontSize: 30,
                                    lineHeight: 1.2,
                                    color: '#0ABBEF',
                                    marginBottom: 10
                                }}>{data.strapline}</p>
                            ) : null}
                            <h1 style={{
                                fontWeight: 'bold',
                                fontSize: 46,
                                lineHeight: 1.3,
                                letterSpacing: 0.56,
                                textTransform: 'uppercase',
                                marginBottom: 5
                            }} dangerouslySetInnerHTML={{ __html: data.heading }}/>
                            {data.preamble ? (
                                <div className="wysiwyg"
                                     dangerouslySetInnerHTML={{ __html: data.preamble }}
                                     style={{
                                         fontSize: 24,
                                         lineHeight: 1.5,
                                         marginBottom: 30
                                     }}/>
                            ) : null}
                        </div>
                        <div style={{
                            width: '200vw',
                            height: 'calc(100% - 540px)',
                            position: 'absolute',
                            top: 540, left: '-100vw',
                            backgroundColor: '#0D2C6D',
                            zIndex: 0
                        }}/>
                    </div>
                </header>
                <div style={{
                    position: 'relative'
                }}>
                    <div style={{
                        width: '200vw',
                        height: '100%',
                        position: 'absolute',
                        top: 0, left: '-100vw',
                        backgroundColor: '#0D2C6D',
                        zIndex: 0
                    }}/>
                    <div ref={areasRef} style={{ position: 'relative', zIndex: 1 }} data-areas>
                        {officeAreas.map(area => (
                            <div key={`area-${area.uid}`} style={{
                                display: 'flex',
                                width: '100%',
                                padding: '80px 13.5% 0 6.5%',
                                minHeight: 700
                            }}>
                                <div style={{
                                    width: 400,
                                    flex: '0 0 auto'
                                }}>
                                    {area.mapImage ? (
                                        <LazyImage src={area.mapImage.url} {...area.mapImage} />
                                    ) : null}
                                </div>
                                <div style={{
                                    paddingLeft: 26
                                }}>
                                    <div style={{
                                        marginBottom: 65
                                    }}>
                                        <h2 style={{
                                            fontSize: 46,
                                            lineHeight: 1.4,
                                            fontWeight: 'bold',
                                            textTransform: 'uppercase'
                                        }}>{area.title}</h2>
                                        {area.description ? (
                                            <p style={{
                                                fontSize: 20,
                                                lineHeight: 1.5
                                            }}>{area.description}</p>
                                        ) : null}
                                    </div>
                                    <div style={{ paddingBottom: 100 }}>
                                        {area.offices.map(office => (
                                            <Link to={`/offices/${office.uid}`} key={`office-${office.uid}`} style={{
                                                display: 'flex',
                                                width: '100%',
                                                minHeight: 200,
                                                padding: '0 0 25px',
                                                borderBottom: '1px solid #0ABBEF',
                                                marginBottom: 28
                                            }}>
                                                <div style={{
                                                    width: 258,
                                                    height: 172,
                                                    position: 'relative',
                                                    flex: '0 0 auto',
                                                    backgroundColor: '#0E2356'
                                                }}>
                                                    {office.image ? (
                                                        <LazyImage src={office.image.url} {...office.image} style={{
                                                            position: 'absolute',
                                                            top: 0, left: 0,
                                                            objectFit: 'cover'
                                                        }}/>
                                                    ) : null}
                                                </div>
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    fontSize: 20,
                                                    lineHeight: 1.5,
                                                    padding: '0 0 0 26px'
                                                }}>
                                                    {office.country ? (
                                                        <p style={{
                                                            color: '#F0AB00',
                                                            fontWeight: 'bold',
                                                            textTransform: 'uppercase'
                                                        }}>{office.country}</p>
                                                    ) : null}
                                                    <h3 style={{
                                                        textTransform: 'uppercase'
                                                    }}>{office.title}</h3>
                                                    {office.officeType.length ? (
                                                        <p dangerouslySetInnerHTML={{ __html: office.officeType.map(officeType => officeType.title ).join('<br/>')}}/>
                                                    ) : null}
                                                    {office.inaugurationYear ? (
                                                        <p>{office.inaugurationYear}</p>
                                                    ) : null}
                                                </div>
                                                <div style={{
                                                    marginLeft: 'auto',
                                                    alignSelf: 'center'
                                                }}>
                                                    <BusinessAreasList
                                                        size="small"
                                                        activeUids={(office.businessAreas || []).filter(category => category.enabled).map(category => category.uid)}
                                                    />
                                                </div>
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <TopLink target={containerRef}/>
            </Scrollable>
        </TransitionScreen>
    );
};

export default OfficeListing;
