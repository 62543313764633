import React from 'react';
import { Transition, TransitionGroup } from "react-transition-group";
import { ReactComponent as MapMarkerSvg } from "../../img/map-marker.svg";

require('gsap');
const TimelineMax = window.TimelineMax;

const WelcomeScreenMap = ({ area, mapMarker, officeType }) => {
    officeType = (officeType || [])[0] || null;
    const markerColor = officeType && officeType.colorTheme === 'white' ? '#ffffff' : '#F0AB00';
    return (
        <div style={{
            width: 1538,
            height: 756,
            margin: 'auto',
            position: 'relative',
            zIndex: 1
        }}>
            {area && area.areaMap ? (
                <>
                    <TransitionGroup component={null}>
                        {mapMarker ? (
                            <Transition
                                timeout={500}
                                key={`marker-${mapMarker.x}${mapMarker.y}`}
                                appear={true}
                                onEntering={node => {
                                    new TimelineMax()
                                        .set(node, { zIndex: 2 })
                                        .fromTo(node, 0.65, { scaleY: 0.001 }, { scaleY: 0.9999, ease: 'Back.easeOut', transformOrigin: 'center bottom' }, 0)
                                        .fromTo(node, 0.75, { y: 50 }, { y: 0, ease: 'Back.easeOut', transformOrigin: 'center bottom' }, 0);
                                        //.fromTo(node, 0.75, { scaleY: 0.1 }, { scaleY: 0.99, ease: 'Back.easeOut', transformOrigin: 'center bottom' }, 0);
                                }}
                                onExiting={node => {
                                    new TimelineMax()
                                        .set(node, { zIndex: 1 })
                                        .to(node, 0.3, { opacity: 0.001, ease: 'Cubic.easeIn' })
                                        .to(node, 0.3, { y: 20, ease: 'Back.easeIn', transformOrigin: 'center bottom' }, 0)
                                        .to(node, 0.3, { scaleY: 0.001, ease: 'Back.easeIn', transformOrigin: 'center bottom' }, 0);
                                        //.to(node, 0.35, { scaleY: 0.1, ease: 'Back.easeIn', transformOrigin: 'center bottom' }, 0);
                                }}
                            >
                                <div style={{
                                    width: 0,
                                    height: 0,
                                    position: 'absolute',
                                    left: `${mapMarker.x}%`,
                                    top: `${mapMarker.y}%`,
                                    zIndex: 2
                                }}>
                                    <MapMarkerSvg style={{
                                        width: 58,
                                        height: 76,
                                        position: 'absolute',
                                        left: -29,
                                        bottom: -10,
                                        fill: markerColor
                                    }}/>
                                </div>
                            </Transition>
                        ) : null}
                    </TransitionGroup>
                    <TransitionGroup component={null}>
                        <Transition timeout={500} key={`areamap-${area.uid}`}>
                            {status => (
                                <img src={area.areaMap.url} width={area.areaMap.width} height={area.areaMap.height} alt="" style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    opacity: ['entering', 'entered'].indexOf(status) > -1 ? 1 : 0.001,
                                    transition: 'opacity 0.5s ease-in-out'
                                }}/>
                            )}
                        </Transition>
                    </TransitionGroup>
                </>
            ) : null}
        </div>
    );
};

export default WelcomeScreenMap;
