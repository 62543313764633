import React from 'react';
import { Link } from "react-router-dom";
import LazyImage from "./LazyImage";

const ArticleCardBig = ({ uid, title, listingTitle, strapline, listingImage }) => (
    <Link to={`/articles/${uid}`} style={{
        display: 'flex',
        width: '100%',
        flex: '0 0 auto',
        backgroundColor: '#F7F6F7',
        borderRadius: 10,
        overflow: 'hidden',
        minHeight: 146,
        boxShadow: '2px 1px 2px 0 rgba(6,22,56,0.50)'
    }} className="clickable">
        {listingImage ? (
            <div style={{
                width: 260,
                position: 'relative',
                overflow: 'hidden',
                flex: '0 0 auto',
                background: listingImage ? `url(${listingImage.lqip})` : null,
                backgroundSize: 'cover'
            }}>
                <LazyImage src={listingImage.url} {...listingImage} style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    top: 0,
                    left: 0,
                    objectFit: 'cover'
                }} />
            </div>
        ) : null}
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            flex: '1 1 auto',
            padding: '20px 18px 18px 18px'
        }}>
            {strapline ? (
                <p style={{
                    color: '#0ABBEF',
                    fontSize: 15,
                    lineHeight: 1.2,
                    textTransform: 'uppercase',
                    marginBottom: 5
                }}>{strapline}</p>
            ) : null}
            <h3 style={{
                fontSize: 22,
                lineHeight: 1.25,
                fontWeight: 'bold',
                textTransform: 'uppercase',
                color: '#0D2C6D'
            }}>
                {listingTitle || title}
            </h3>
        </div>
    </Link>
);

export default ArticleCardBig;
