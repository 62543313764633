import React from 'react';
import LazyImage from "./LazyImage";

const ArticleTopBanner = ({ image }) => (
    <div style={{
        width: '100%',
        height: 540,
        backgroundColor: '#001D5D',
        position: 'fixed',
        top: 0,
        left: 0,
        overflow: 'hidden',
        zIndex: -1
    }}>
        {image ? (
            <LazyImage src={image.url} {...image} style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                objectFit: 'cover'
            }}/>
        ) : null}
    </div>
);

export default ArticleTopBanner;
