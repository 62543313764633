import React, { useEffect, useState, useRef } from 'react';
import chunk from "lodash/chunk";
import uniq from "lodash/uniq";
import ArticleCard from "../ArticleCard";
import { ReactComponent as ArrowSvg } from "../../img/btn-back.svg";
import { getQueryParam } from "../../helpers";
import Flickity from "react-flickity-component";

import styles from '../../styles/timeline.module.scss';

const TimelineItemPanelChunk = ({ chunk }) => (
    <div className={styles.itempanelchunk} style={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        minHeight: '100%',
        flex: '0 0 auto',
        padding: '0 120px'
    }}>
        <div style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: chunk.length < 4 ? 'center' : 'flex-start',
            alignContent: 'flex-start',
            width: '100%',
            maxWidth: 1680,
            margin: '0 auto'
        }}>
            {chunk.map(article => (
                <div key={`timeline-article-${article.uid}`} style={{
                    display: 'flex',
                    width: '25%',
                    flex: '0 0 auto',
                    pointerEvents: 'auto',
                    padding: 11
                }}>
                    <ArticleCard {...article} style={{ display: 'flex' }}/>
                </div>
            ))}
        </div>
    </div>
);

const TimelineItemPanel = ({ uid, startYear, endYear, text, articles }) => {

    let flickityRef = useRef(null);

    const uidInUrl = getQueryParam('item');
    const pageInUrl = parseInt(getQueryParam('page') || 0, 10);
    const initialPage = uidInUrl === uid ? pageInUrl : 0;

    const [activeChunk, setActiveChunk] = useState(initialPage);

    useEffect(() => {
        let queryString = '';
        if (uid) {
            queryString = `?item=${uid}`;
            if (activeChunk) {
                queryString += `&page=${activeChunk}`;
            }
        }
        const { pathname } = window.location;
        const url = `${pathname}${queryString}`;
        window.history.replaceState(null, null, url);
    }, [uid, activeChunk]);

    if (!uid) {
        return null;
    }

    const articleChunks = chunk(articles || [], 8);

    const nextChunk = () => {
        const { current: flickity } = flickityRef;
        if (!flickity) {
            return;
        }
        flickity.next();
    };

    const prevChunk = () => {
        const { current: flickity } = flickityRef;
        if (!flickity) {
            return;
        }
        flickity.previous();
    };

    return (
        <div key={`item-content-${uid}`} style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            left: 0,
            top: 0,
            zIndex: 2,
            width: '100%',
            height: '100%',
        }}>
            <div style={{
                width: '100%',
                display: 'flex',
                maxWidth: 1680,
                margin: '0 auto',
                flex: '0 0 auto',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '0 120px'
            }}>
                <h3 style={{
                    fontSize: 46,
                    lineHeight: 1.3,
                    fontWeight: 'bold'
                }}>{uniq([startYear, endYear]).join(' - ')}</h3>
                {text ? (
                    <div style={{
                        fontSize: 20,
                        lineHeight: 1.5,
                        marginTop: 5
                    }} dangerouslySetInnerHTML={{ __html: text }}/>
                ) : null}
            </div>
            <div className={styles.itempanels} style={{
                width: '100%',
                height: '100%',
                flex: '1 1 auto',
                position: 'relative',
                zIndex: 1
            }}>
                {articleChunks.length > 1 ? (
                    <Flickity options={{
                        pageDots: false,
                        prevNextButtons: false,
                        wrapAround: true,
                        disableImagesLoaded: true,
                        contain: true,
                        adaptiveHeight: false,
                        initialIndex: initialPage,
                        on: {
                            select: index => setActiveChunk(index),
                            dragStart: () => {
                                document.ontouchmove = e => e.preventDefault();
                                const { current: flickity } = flickityRef;
                                if (!flickity) {
                                    return;
                                }
                                const { element } = flickity;
                                const clickables = Array.from(element.querySelectorAll('a,button'));
                                if (clickables.length) {
                                    clickables.forEach(clickable => clickable.style.pointerEvents = 'none');
                                }
                            },
                            dragEnd: () => {
                                document.ontouchmove = () => true;
                                requestAnimationFrame(() => {
                                    const { current: flickity } = flickityRef;
                                    if (!flickity) {
                                        return;
                                    }
                                    const { element } = flickity;
                                    const clickables = Array.from(element.querySelectorAll('a,button'));
                                    if (clickables.length) {
                                        clickables.forEach(clickable => clickable.style.pointerEvents = '');
                                    }
                                });
                            }
                        }
                    }} flickityRef={ref => {
                        flickityRef.current = ref;
                    }}>
                        {articleChunks.map((articleChunk, index) => (
                            <TimelineItemPanelChunk key={`itemchunk-${index}`} chunk={articleChunk}/>
                        ))}
                    </Flickity>
                ) : (
                    <TimelineItemPanelChunk chunk={articleChunks[0] || []}/>
                )}
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                flex: '0 0 auto',
                marginTop: 'auto',
                padding: '0 120px 50px',
                position: 'relative',
                zIndex: 2,
                opacity: articleChunks.length > 1 ? 1 : 0,
                pointerEvents: articleChunks.length > 1 ? '' : 'none'
            }}>
                <button className="clickable" style={{
                    width: 44,
                    height: 44,
                    pointerEvents: 'auto',
                    position: 'relative'
                }} onClick={() => prevChunk()}>
                    <ArrowSvg style={{
                        width: 25,
                        height: 43,
                        position: 'absolute',
                        left: '50%',
                        top: '50%',
                        margin: '-21px 0 0 -14px'
                    }}/>
                </button>
                {articleChunks.map((articleChunk, index) => (
                    <button key={`article-chunk-btn-${index}`} style={{
                        width: 30,
                        height: 30,
                        marginTop: -2,
                        pointerEvents: 'auto'
                    }} onClick={() => {
                        const { current: flickity } = flickityRef;
                        if (!flickity) {
                            return;
                        }
                        flickity.select(index);
                    }}>
                            <span style={{
                                display: 'block',
                                width: 20,
                                height: 20,
                                borderRadius: '100%',
                                backgroundColor: index === activeChunk ? '#0ABBEF' : '#031644',
                                margin: '5px 0 0 5px'
                            }}/>
                    </button>
                ))}
                <button className="clickable" style={{
                    width: 44,
                    height: 44,
                    pointerEvents: 'auto',
                    position: 'relative'
                }} onClick={() => nextChunk()}>
                    <ArrowSvg style={{
                        width: 25,
                        height: 43,
                        position: 'absolute',
                        left: '50%',
                        top: '50%',
                        margin: '-21px 0 0 -11px',
                        transform: 'scale(-1, 1)'
                    }}/>
                </button>
            </div>
        </div>
    );
};

export default TimelineItemPanel;
