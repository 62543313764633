import React, { useEffect, useState, useRef } from 'react';
import { withRouter, Link } from "react-router-dom";
import { connect } from 'react-redux';
import { Transition } from "react-transition-group";
import find from 'lodash/find';
import classNames from 'classnames';
import api from '../api';

import { ReactComponent as HamburgerSvg } from '../img/hamburger.svg';
import { ReactComponent as CloseSvg } from '../img/btn-close.svg';
import { ReactComponent as HoriArrowSvg } from '../img/hori-arrow.svg';
import { ReactComponent as DotSvg } from '../img/dot.svg';
import { ReactComponent as HomeSvg } from '../img/home.svg';

import { setMenuOpen } from "../store";

import styles from '../styles/menu.module.scss';

require('gsap');
const TimelineMax = window.TimelineMax;
const TweenMax = window.TweenMax;

const Menu = ({ history, activeStationUid, menuOpen, dispatch }) => {

    const scrollableRef = useRef(null);

    const [data, setData] = useState(null);
    const [expandedItem, setExpandedItem] = useState(null);

    const toggleMenu = () => {
        if (menuOpen) {
            dispatch(setMenuOpen(false));
            return;
        }
        dispatch(setMenuOpen(true));
    };

    useEffect(() => {
        api
            .get('menu')
            .then(({ data }) => data)
            .then(({ data }) => setData(data))
            .catch(error => {
                console.error(error);
            });
    }, []);

    useEffect(() => {
        const listener = history.listen((location, action) => {
            dispatch(setMenuOpen(false));
        });
        return () => {
            listener();
        };
    }, [history, dispatch]);

    useEffect(() => {
        if (menuOpen) {
            return;
        }
        setExpandedItem(null);
    }, [menuOpen]);

    if (data === null) {
        return null;
    }

    const segments = history.location.pathname.split('/');
    const activeUid = (segments[1] || null) === 'articles' ? segments[2] || null : null;

    return (
        <>
            {/* Menu toggle */}
            <button className={classNames('clickable', styles.toggle)}
                    onClick={() => toggleMenu()}>
                {!menuOpen ? (
                    <HamburgerSvg style={{
                        width: 44,
                        height: 40,
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        margin: '-20px 0 0 -22px'
                    }}/>
                ) : (
                    <CloseSvg style={{
                        width: 60,
                        height: 60,
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        margin: '-30px 0 0 -30px'
                    }}/>
                )}
            </button>
            <Transition
                in={menuOpen}
                timeout={500}
                appear={true}
                unmountOnExit={true}
                onEntering={node => {
                    const items = Array.from(node.querySelector('nav').childNodes);
                    new TimelineMax()
                        .fromTo(node, 0.3, { opacity: 0.0001 }, {
                            opacity: 1,
                            ease: 'Cubic.easeIn'
                        }, 0)
                        .staggerFromTo(items, 0.15, { opacity: 0.0001 }, {
                            opacity: 1,
                            ease: 'Linear.easeNone'
                        }, 0.05, 0.15)
                        .staggerFromTo(items, 0.5, { y: -40 }, {
                            y: 0, ease: 'Quint.easeOut', onComplete: tween => {
                                // Have to do this due to a rendering bug in Chrome
                                TweenMax.set(tween.target, { clearProps: 'all' });
                            },
                            onCompleteParams: ['{self}']
                        }, 0.05, 0.15);
                }}
                onExiting={node => {
                    TweenMax.to(node, 0.3, { opacity: 0, ease: 'Cubic.easeIn' });
                }}
            >
                <div ref={scrollableRef} className={classNames('u-scrollable', styles.menu)}>
                    <nav className={styles.items}>

                        {activeStationUid ? (
                            <div className={classNames(styles.item)}>
                                <Link to={`/stations/${activeStationUid}`}>
                                    <HomeSvg style={{
                                        width: 23,
                                        height: 24,
                                        marginTop: -12
                                    }}/>
                                    <span>Home</span>
                                </Link>
                            </div>
                        ) : null}

                        {(data || []).map(entry => {
                            const hasDescendants = !!entry.descendants.length;
                            const isContainer = entry.type === 'container';
                            const renderAsContainer = isContainer || hasDescendants;
                            const isActive = entry.uid === activeUid;
                            const isChildActive = !!find(entry.descendants, { uid: activeUid });
                            const isExpanded = renderAsContainer && expandedItem === entry.uid;
                            // If a top level entry is *not* a container, inject a link to the actual entry in descendants
                            if (!isContainer && renderAsContainer && !find(entry.descendants, { uid: entry.uid })) {
                                entry.descendants = [{
                                    uid: entry.uid,
                                    title: entry.title
                                }].concat(entry.descendants);
                            }
                            return (
                                <div key={entry.uid}
                                     className={classNames(styles.item, isActive || isChildActive ? 'is-active' : null, isExpanded ? 'is-expanded' : null)}>
                                    {renderAsContainer ? (
                                        <div>
                                            <button onClick={() => {
                                                if (!menuOpen || !entry.descendants.length || expandedItem === entry.uid) {
                                                    setExpandedItem(null);
                                                    return;
                                                }
                                                setExpandedItem(entry.uid);
                                            }}>
                                                <HoriArrowSvg style={{
                                                    width: 12,
                                                    height: 20
                                                }}/>
                                                <span>{entry.title}</span>
                                            </button>
                                            <Transition
                                                in={isExpanded}
                                                timeout={500}
                                                appear={true}
                                                unmountOnExit={true}
                                                onEntering={node => {
                                                    const height = node.getBoundingClientRect().height;
                                                    new TimelineMax()
                                                        .fromTo(node, 0.5, { height: 0 }, { height, ease: 'Cubic.easeInOut' }, 0)
                                                        .fromTo(node, 0.3, { opacity: 0 }, { opacity: 1, ease: 'Cubic.easeIn' }, 0)
                                                        .add(() => {
                                                            const scrollable = scrollableRef.current;
                                                            if (!scrollable) {
                                                                return;
                                                            }
                                                            let scrollTop = scrollable.scrollTop;
                                                            const { height: scrollableHeight } = scrollable.getBoundingClientRect();
                                                            const { top } = node.getBoundingClientRect();
                                                            const bottom = (top - scrollTop) + height;
                                                            const pixelsBelowTheFold = bottom - scrollableHeight;
                                                            if (pixelsBelowTheFold > -20) {
                                                                scrollTop += (pixelsBelowTheFold + 20);
                                                                TweenMax.to(scrollable, 0.5, { scrollTop, ease: 'Cubic.easeInOut' });
                                                            }
                                                        }, 0);
                                                }}
                                                onExiting={node => {
                                                    new TimelineMax()
                                                        .to(node, 0.5, { height: 0, ease: 'Cubic.easeInOut' }, 0)
                                                        .to(node, 0.3, { opacity: 0, ease: 'Cubic.easeIn' }, 0);
                                                }}
                                            >
                                                <div style={{ overflow: 'hidden' }}>
                                                    <ul>
                                                        {entry.descendants.map(childEntry => (
                                                            <li key={childEntry.uid}
                                                                className={classNames(styles.childitem, childEntry.uid === activeUid ? 'is-active' : null)}>
                                                                <Link
                                                                    to={`/articles/${childEntry.uid}`}>
                                                                    <DotSvg style={{
                                                                        width: 12,
                                                                        height: 12
                                                                    }}/>
                                                                    <span>{childEntry.title}</span>
                                                                </Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </Transition>
                                        </div>
                                    ) : (
                                        <Link to={`/articles/${entry.uid}`}>
                                            <DotSvg style={{
                                                width: 16,
                                                height: 16
                                            }}/>
                                            <span>{entry.title}</span>
                                        </Link>
                                    )}
                                </div>
                            );
                        })}
                    </nav>
                </div>
            </Transition>
        </>
    );
};

export default withRouter(
    connect(state => ({
        activeStationUid: state.activeStationUid,
        menuOpen: state.menuOpen
    }))(Menu)
);
