import React from 'react';
import classNames from 'classnames';

const Scrollable = ({ forwardedRef, children, className, onScroll, ...props }) => (
    <div ref={forwardedRef} className={classNames('u-scrollable', className)} {...props} onScroll={onScroll}>
        {children}
    </div>
);

export default React.forwardRef((props, ref) => (
    <Scrollable forwardedRef={ref} {...props}/>
));
