import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import axios from 'axios';
import api from '../api';

import WelcomeScreenOffice from "./WelcomeScreenOffice";

const WelcomeScreen = ({ officeInterval, officeImageInterval}) => {

    const [currentOfficeData, setCurrentOfficeData] = useState(null);
    const [currentOfficeUid, setCurrentOfficeUid] = useState(null);

    useEffect(() => {

        if (officeInterval === null || officeImageInterval === null) {
            return;
        }

        let cancelTokenSource = axios.CancelToken.source();
        let timeout;

        api
            .get('offices', {
                cancelToken: cancelTokenSource.token
            })
            .then(({ data }) => data)
            .then(({ data }) => (data || []).map(office => office.uid))
            .then(uids => {
                const currentOfficeUidIndex = uids.indexOf(currentOfficeUid);
                const nextOfficeUidIndex = currentOfficeUidIndex < uids.length - 1 ? currentOfficeUidIndex + 1 : 0;
                const nextOfficeUid = uids[nextOfficeUidIndex];
                const numImagesInCurrentOffice = currentOfficeData ? (currentOfficeData.images4k || []).length : 0;
                let interval = officeImageInterval * numImagesInCurrentOffice;
                if (interval < officeInterval) {
                    interval = officeInterval;
                }
                cancelTokenSource = axios.CancelToken.source();
                timeout = setTimeout(() => {
                    api
                        .get(`offices/${nextOfficeUid}`, { cancelToken: cancelTokenSource.token })
                        .then(({ data }) => setCurrentOfficeData(data))
                        .catch(error => {
                            if (axios.isCancel(error)) {
                                return;
                            }
                            console.error(error);
                        })
                        .then(() => {
                            cancelTokenSource = null;
                            setCurrentOfficeUid(nextOfficeUid);
                        });
                }, currentOfficeUid ? interval : 0);
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    return;
                }
                console.error(error);
            });

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
            if (cancelTokenSource) {
                cancelTokenSource.cancel();
            }
        };
    }, [currentOfficeData, currentOfficeUid, officeInterval, officeImageInterval]);

    if (!currentOfficeData || officeInterval === null || officeImageInterval === null) {
        return null;
    }

    return (
        <WelcomeScreenOffice data={currentOfficeData} loopGallery={false}/>
    );
};

export default connect(state => ({
    officeInterval: get(state, 'settings.welcomeScreenOfficeInterval', null),
    officeImageInterval: get(state, 'settings.welcomeScreenOfficeImageSlideshowInterval', null)
}))(WelcomeScreen);
