import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router-dom';
import { Transition, TransitionGroup } from "react-transition-group";

import Index from "./Index";
import StationIndex from "./StationIndex";
import Station from "./Station";
import OfficeArticle from "./offices/OfficeArticle";
import Article from "./Article";
import BackButton from "./BackButton";
import Menu from "./Menu";
import ModalMount from "./ModalMount";

import get from "lodash/get";
import { setMenuOpen, setModal } from "../store";

import styles from '../styles/contentscreens.module.scss';
import { getPreviewToken } from "../api";

export const SCREEN_TRANSITION_TIME = 300;

const ANTI_BURNIN_POSITIONS = [-5, 0];

const ContentScreens = ({ location, dispatch, history, hasModal, videoIsPlaying, timeoutInterval, antiBurnInInterval, activeStationUid }) => {

    // Inactivity timeout
    useEffect(() => {
        const previewToken = getPreviewToken();
        const path = (location.pathname || '').replace(/\/+$/, '');
        if (previewToken || timeoutInterval === null || ['/stations'].indexOf(path) > -1) {
            console.log('dont create inactivity timeout');
            return;
        }
        let timeout;
        const resetTimeout = () => {
            if (timeout) {
                clearTimeout(timeout);
            }
            if (videoIsPlaying) {
                console.info('video is playing, dont timeout');
                return;
            }
            timeout = setTimeout(() => {
                const url = activeStationUid ? `/stations/${activeStationUid}` : '/';
                const pathname = window.location.pathname;
                if (pathname !== url) {
                    history.push(url);
                }
                dispatch(setModal(null));
                dispatch(setMenuOpen(false));
                resetTimeout();
            }, timeoutInterval);
            console.info('timeout reset, created new timeout');
        };
        // Reset timeout on interaction
        document.body.addEventListener('click', resetTimeout);
        document.body.addEventListener('mousedown', resetTimeout);
        document.body.addEventListener('touchstart', resetTimeout);
        document.body.addEventListener('touchmove', resetTimeout);
        resetTimeout();
        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
            document.body.removeEventListener('click', resetTimeout);
            document.body.removeEventListener('mousedown', resetTimeout);
            document.body.removeEventListener('touchstart', resetTimeout);
            document.body.removeEventListener('touchmove', resetTimeout);
            console.info('inactivity timeout cleared');
        };
    }, [history, location, dispatch, activeStationUid, timeoutInterval, videoIsPlaying]);

    // Anti burn-in timeout
    const [position, setPosition] = useState(0);
    useEffect(() => {
        if (!antiBurnInInterval) {
            return;
        }
        console.log({ antiBurnInInterval });
        let timeout;
        const resetTimeout = () => {
            if (timeout) {
                clearTimeout(timeout);
            }
            timeout = setTimeout(() => {
                console.log('::: ANTIBURN TIMEOUT :::');
                const newPosition = position < ANTI_BURNIN_POSITIONS.length - 1 ? position + 1 : 0;
                setPosition(newPosition);
                resetTimeout();
            }, antiBurnInInterval);
        };
        // Reset timeout on navigation
        const historyListener = history.listen(() => {
            resetTimeout();
        });
        resetTimeout();
        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
            historyListener();
        };
    }, [antiBurnInInterval, hasModal, history, position]);

    // Scale screen
    const [scale, setScale] = useState(1);

    const resizeHandler = useCallback(() => {
        // Only scale for Live Preview
        const token = getPreviewToken();
        if (!token) {
            setScale(1);
            return;
        }
        const winWidth = window.innerWidth;
        if (winWidth < 1920) {
            setScale(winWidth / 1920);
        } else {
            setScale(1);
        }
    }, []);

    useEffect(() => {
        window.addEventListener('resize', resizeHandler);
        resizeHandler();
        return () => {
            window.removeEventListener('resize', resizeHandler);
        };
    }, [resizeHandler]);

    console.log({ scale });

    return (
        <>
            <TransitionGroup component={null}>
                <Transition key={location.key} timeout={SCREEN_TRANSITION_TIME}
                            appear={true}>
                    {status => (
                        <div className={styles.root} style={{
                            transform: `translate(${ANTI_BURNIN_POSITIONS[position]}px,${ANTI_BURNIN_POSITIONS[position]}px) scale(${scale}, ${scale})`,
                            transformOrigin: 'left top'
                        }}>
                            <Switch location={location}>
                                <Route exact path="/" render={props => <Index
                                    status={status} {...props}/>}/>
                                <Route exact path="/stations" render={props => <StationIndex
                                    status={status} {...props}/>}/>
                                <Route exact path="/stations/:uid" render={props => <Station
                                    status={status} {...props}/>}/>
                                <Route exact path="/offices/:uid"
                                       render={props => <OfficeArticle
                                           status={status} {...props}/>}/>
                                <Route exact path="/articles/:uid" render={props => <Article
                                    status={status} {...props}/>}/>
                            </Switch>
                        </div>
                    )}
                </Transition>
            </TransitionGroup>
            {(location.pathname.split('/')[1] || '') !== 'stations' ? (
                <div style={{ position: 'relative', zIndex: 2 }}>
                    <BackButton/>
                </div>
            ) : null}
            <div style={{ position: 'relative', zIndex: 3 }}>
                <Menu/>
            </div>
            <ModalMount/>
        </>
    );
};

export default withRouter(connect(state => ({
    activeStationUid: state.activeStationUid,
    timeoutInterval: get(state, 'settings.contentScreenTimeout', null),
    antiBurnInInterval: get(state, 'settings.stationAntiBurnInInterval', null),
    hasModal: !!state.modal,
    videoIsPlaying: state.videoIsPlaying
}))(ContentScreens));
