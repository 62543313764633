import React from 'react';
import { connect } from 'react-redux';
import LazyImage from "../LazyImage";
import styles from "../../styles/station.module.scss";

import { COLUMN_WIDTH, GUTTER, ROW_HEIGHT } from "../Station";

import { setModal } from "../../store";

import { ReactComponent as PlayBtnSvg } from '../../img/btn-play.svg';

const StationMediaPlug = ({ dispatch, className, ...data }) => {
    const image = data.image ? data.image.large : null;
    return (
        <button to={`/articles/${data.uid}`} className={className} style={{
            width: (COLUMN_WIDTH * 2) + (GUTTER * 2),
            height: ROW_HEIGHT,
            margin: GUTTER
        }} onClick={() => dispatch(setModal(data))}>
            <div style={{
                backgroundColor: '#002066'
            }}>
                {image ? (
                    <LazyImage src={image.url} {...image} style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                    }}/>
                ) : null}
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'absolute',
                    left: 0,
                    bottom: 0,
                    backgroundColor: data.type === 'timeline' ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.75)',
                    width: '100%',
                    padding: '20px 25px 20px 20px',
                    transform: 'translate3d(0, 0, 0)'
                }}>
                    {data.type === 'video' ? (
                        <PlayBtnSvg style={{
                            width: 48,
                            height: 48,
                            flex: '0 0 auto',
                            marginRight: 15
                        }}/>
                    ) : null}
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        width: '100%',
                        flex: '1 1 auto'
                    }}>
                        {data.strapline ? (
                            <p className={styles.strapline} style={{
                                fontSize: 15,
                                lineHeight: 1.2,
                                marginBottom: 5
                            }}>{data.strapline}</p>
                        ) : null}
                        <h3 className={styles.heading} dangerouslySetInnerHTML={{ __html: data.title }}
                            style={{
                                fontSize: 18,
                                lineHeight: 1.2
                            }}/>
                    </div>
                </div>
            </div>
        </button>
    );
};

export default connect()(StationMediaPlug);
