import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import axios from 'axios';
import api, { getPreviewToken } from '../../api';
import ArticleTopBanner from "../ArticleTopBanner";
import TopLink from "../TopLink";
import Scrollable from "../Scrollable";
import ClickToStartScreen from "../ClickToStartScreen";
import VideoJukeboxesPlug from "./VideoJukeboxesPlug";

import { GUTTER } from "../Station";

const REFRESH_INTERVAL = 30000; // Fetch fresh data every 30 secs

class VideoJukeboxesEntry extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: null
        };
        this.uid = props.uid || get(props, 'match.params.uid');
        this.cancelAjaxTokenSource = null;
        this.fetchDataTimer = null;
        this.containerRef = React.createRef();
        this.isPreview = !!getPreviewToken();
    }

    fetchData() {
        if (this.fetchDataTimer) {
            clearTimeout(this.fetchDataTimer);
            this.fetchDataTimer = null;
        }
        if (this.cancelAjaxTokenSource) {
            this.cancelAjaxTokenSource.cancel();
            this.cancelAjaxTokenSource = null;
        }
        this.cancelAjaxTokenSource = axios.CancelToken.source();
        api
            .get(`video-jukeboxes/${this.uid}`, {
                params: { token: getPreviewToken() },
                cancelToken: this.cancelAjaxTokenSource.token
            })
            .then(({ data: newData }) => {
                console.info('got new data!');
                this.setState({
                    data: newData
                });
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    return;
                }
                console.error(error);
            })
            .then(() => {
                this.cancelAjaxTokenSource = null;
                // Do it again in 30 secs, make sure the data is fresh
                this.fetchDataTimer = setTimeout(() => {
                    this.fetchData();
                }, REFRESH_INTERVAL);
            });
    }

    componentDidMount() {
        this.fetchData();
    }

    componentWillUnmount() {
        if (this.cancelAjaxTokenSource) {
            this.cancelAjaxTokenSource.cancel();
        }
        if (this.fetchDataTimer) {
            clearTimeout(this.fetchDataTimer);
        }
        if (this.nextSegmentTimer) {
            clearTimeout(this.nextSegmentTimer);
        }
    }

    render() {

        if (!this.state.data) {
            return null;
        }

        const { title, heading, preamble, videos } = this.state.data || {};

        const containerRef = this.containerRef;

        return (
            <Scrollable ref={containerRef} className="screen">
                {/* Top/bg graphics */}
                <ArticleTopBanner/>
                {/* Main wrapper */}
                <div style={{
                    width: '100%',
                    minHeight: '100vh',
                    paddingLeft: 120,
                    paddingRight: 120,
                    paddingTop: 110,
                    paddingBottom: 80,
                    position: 'relative',
                }}>
                    <div style={{
                        width: '100%',
                        height: 'calc(100% - 540px)',
                        position: 'absolute',
                        top: 540, left: 0,
                        backgroundColor: '#0D2C6D',
                        zIndex: 0
                    }}/>
                    {/* Header (title etc) */}
                    <header style={{
                        width: '58%',
                        flex: '0 0 auto',
                        position: 'relative',
                        zIndex: 1,
                        padding: '0 40px 40px'
                    }}>
                        <h1 style={{
                            fontWeight: 'bold',
                            fontSize: 68,
                            lineHeight: 1.2,
                            letterSpacing: 0.83,
                            textTransform: 'uppercase',
                            marginBottom: preamble ? 10 : 0
                        }} dangerouslySetInnerHTML={{ __html: (heading || title) }}/>
                        {preamble ? (
                            <div className="wysiwyg" dangerouslySetInnerHTML={{ __html: preamble }}
                                 style={{
                                     fontSize: 24,
                                     lineHeight: 1.5
                                 }}/>
                        ) : null}
                    </header>
                    {/* Main content */}
                    <div style={{
                        display: 'flex',
                        width: `calc(100% + ${GUTTER * 2}px`,
                        flexWrap: 'wrap',
                        paddingBottom: 60,
                        position: 'relative',
                        left: -GUTTER
                    }}>
                        {(videos || []).map(video => (
                            <div key={video.uid} style={{
                                width: '33.3333%',
                                position: 'relative'
                            }}>
                                <div style={{ width: '100%', padding: GUTTER }}>
                                    <VideoJukeboxesPlug {...video} />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <TopLink target={containerRef}/>
            </Scrollable>
        );
    }

}

export default connect(state => ({
    userHasInteractedWithScreen: state.userHasInteractedWithScreen
}))(VideoJukeboxesEntry);
