import React from 'react';
import { Transition } from "react-transition-group";

import { SCREEN_TRANSITION_TIME } from "./ContentScreens";

require('gsap');
const TimelineMax = window.TimelineMax;

const TransitionScreen = ({ status, children }) => {
    return (
        <Transition
            in={['exiting', 'exited'].indexOf(status) === -1}
            appear={true}
            unmountOnExit={true}
            timeout={SCREEN_TRANSITION_TIME}
            onEntering={node => {
                if (!node) {
                    return;
                }
                requestAnimationFrame(() => {
                    new TimelineMax()
                        .fromTo(node, 0.3, { opacity: 0.0001 }, { opacity: 1 }, 0)
                        .set(node, { clearProps: 'opacity' });
                });
            }}
            onExiting={node => {
                if (!node) {
                    return;
                }
                new TimelineMax()
                    .to(node, 0.3, { opacity: 0 });
            }}
        >
            {children}
        </Transition>
    );
};

export default TransitionScreen;
