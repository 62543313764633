import React from 'react';
import LazyImage from "./LazyImage";

import { ReactComponent as PlayBtnSvg } from '../img/btn-play.svg';

const ArticleContentVideo = ({ video, posterImage, caption }) => (
    <figure style={{ marginBottom: 40 }}>
        <div style={{
            height: 0,
            paddingTop: `${(9 / 16) * 100}%`,
            backgroundColor: 'black',
            position: 'relative',
            overflow: 'hidden',
            background: posterImage ? `url(${posterImage.lqip})` : null,
            backgroundSize: 'cover'
        }}>
            {posterImage ? (
                <LazyImage src={posterImage.url} {...posterImage} style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    top: 0,
                    left: 0,
                    zIndex: 1
                }}/>
            ) : null}
            <div style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 2
            }}>
                <PlayBtnSvg style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    width: 60,
                    height: 60,
                    margin: '-30px 0 0 -30px'
                }}/>
            </div>
        </div>
        {caption || video.credit ? (
            <figcaption style={{
                fontSize: 13,
                lineHeight: 1.6,
                fontStyle: 'italic',
                paddingTop: 10
            }}>
                {caption ? (
                    <p>{caption}</p>
                ) : null}
                {video.credit ? (
                    <p>{video.credit}</p>
                ) : null}
            </figcaption>
        ) : null}
    </figure>
);

export default ArticleContentVideo;
