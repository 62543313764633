import React from 'react';
import { TransitionGroup, Transition } from "react-transition-group";

require('gsap');
const TimelineMax = window.TimelineMax;

const WelcomeScreenHeader = ({ uid, title, country }) => (
    <div style={{
        textTransform: 'uppercase',
        lineHeight: 1.2,
        textAlign: 'center',
        position: 'relative',
        overflow: 'hidden',
        height: 600
    }}>
        <TransitionGroup component={null}>
            <Transition
                timeout={500}
                key={`header-${uid}`}
                appear={true}
                onEntering={node => {
                    new TimelineMax()
                        .fromTo(node, 0.75, { yPercent: 100 }, { yPercent: 0, ease: 'Quint.easeOut' }, 0.5)
                        .fromTo(node, 0.3, { opacity: 0.001 }, { opacity: 1, ease: 'Cubic.easeIn' }, 0.5);
                }}
                onExiting={node => {
                    new TimelineMax()
                        .to(node, 0.5, { yPercent: 100, ease: 'Quint.easeIn' }, 0)
                        .to(node, 0.3, { opacity: 0.001, ease: 'Cubic.easeIn' }, 0.2);
                }}
            >
                <div style={{
                    width: '100%',
                    position: 'absolute',
                    bottom: 0, left: 0
                }}>
                    <p style={{
                        fontSize: 50,
                    }}>{country}</p>
                    <h1 style={{
                        color: '#F0AB00',
                        fontWeight: 'bold',
                        fontSize: 96,
                        marginBottom: 40
                    }}>{title}</h1>
                </div>
            </Transition>
        </TransitionGroup>
        <div style={{
            width: '100%',
            maxWidth: 1200,
            height: 4,
            backgroundColor: '#002664',
            position: 'absolute',
            left: '50%',
            bottom: 0,
            transform: 'translate(-50%, 0)'
        }}/>
    </div>
);

export default WelcomeScreenHeader;
