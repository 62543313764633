import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { ReactComponent as MapMarkerSvg } from "../../img/map-marker.svg";

const WelcomeScreenFooter = ({ officeTypeCategories, officeType: activeOfficeTypes }) => {
    if (!officeTypeCategories || !officeTypeCategories.length) {
        return null;
    }
    const activeOfficeTypeUids = (activeOfficeTypes || []).map(officeType => officeType.uid);
    return (
        <ul style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            minHeight: 160,
            backgroundColor: '#002664',
            marginTop: 'auto',
            flex: '0 0 auto',
            fontSize: 36,
            lineHeight: 1.2,
            textTransform: 'uppercase'
        }}>
            {officeTypeCategories.map(category => (
                <li key={category.uid} style={{
                    display: 'flex',
                    alignItems: 'center',
                    margin: '40px 50px',
                    maxWidth: 560,
                    color: category.colorTheme === 'white' ? '#ffffff' : '#F0AB00',
                    opacity: activeOfficeTypeUids.indexOf(category.uid) > -1 ? 1 : 0.25,
                    transition: 'opacity 0.3s ease-in-out'
                }}>
                    <span style={{ display: 'inline-block', flex: '0 0 auto' }}>
                        <MapMarkerSvg style={{ width: 58, height: 76, marginRight: 30, marginTop: -10, fill: category.colorTheme === 'white' ? '#ffffff' : '#F0AB00' }}/>
                    </span>
                    <span style={{ display: 'inline-block' }}>{category.title}</span>
                </li>
            ))}
        </ul>
    );
};

export default connect(state => ({
    officeTypeCategories: get(state, 'settings.officeTypes', [])
}))(WelcomeScreenFooter);
