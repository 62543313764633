import React, { Component } from 'react';
import { Transition } from "react-transition-group";
import api, { getPreviewToken } from "../api";
import { connect } from 'react-redux';
import LazyImage from "./LazyImage";

import { ReactComponent as PlaySvg } from '../img/btn-play.svg';
import { ReactComponent as PauseSvg } from '../img/btn-pause.svg';
import { ReactComponent as RewindSvg } from '../img/btn-rewind.svg';
import { setVideoIsPlaying } from "../store";

require('gsap');
const TweenMax = window.TweenMax;

const HIDE_CONTROLS_TIMEOUT = 2000;

require('gsap');
const TimelineMax = window.TimelineMax;

class Video extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data || null,
            isMounted: true,
            videoPlaying: false,
            videoPaused: false,
            videoEnded: false,
            hideControls: false,
            progress: 0
        };
        this.ref = React.createRef();
        this.videoRef = React.createRef();
        this.progressRef = React.createRef();
        this.togglePause = this.togglePause.bind(this);
        this.rewindVideo = this.rewindVideo.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    rewindVideo() {
        const { current: video } = this.videoRef;
        if (!video) {
            return;
        }
        video.currentTime = 0;
        video.play();
    }

    togglePause() {
        const { current: video } = this.videoRef;
        if (!video) {
            return;
        }
        if (video.paused) {
            video.play();
            this.setState({
                videoPaused: false
            });
        } else {
            video.pause();
        }
    }

    togglePlay() {
        const { current: video } = this.videoRef;
        if (!video) {
            return;
        }
        const { videoPlaying } = this.state;
        if (!videoPlaying) {
            video.play().catch(error => {
                console.error(error)
            });
            const volume = { value: 0 };
            TweenMax.to(volume, 0.5, {
                value: 1,
                onUpdate: () => {
                    video.volume = volume.value;
                }
            });
        } else {
            this.setState({ videoPlaying: false });
            const volume = { value: video.volume };
            TweenMax.to(volume, 0.5, {
                value: 0,
                onUpdate: () => {
                    video.volume = volume.value;
                },
                onComplete: () => {
                    video.pause();
                    setTimeout(() => {
                        if (video.paused) {
                            video.currentTime = 0;
                            video.volume = 0;
                        }
                    }, 1000);
                }
            });
        }
    }

    clearHideControlsTimer() {
        if (!this.hideControlsTimer) {
            return;
        }
        clearTimeout(this.hideControlsTimer);
    }

    createHideControlsTimer() {
        this.clearHideControlsTimer();
        this.hideControlsTimer = setTimeout(() => {
            this.setState({
                hideControls: true
            });
        }, HIDE_CONTROLS_TIMEOUT);
    }

    onClick() {
        if (!this.state.videoPlaying) {
            return;
        }
        this.setState({
            hideControls: false
        });
        this.createHideControlsTimer();
    }

    onVideoPlaying() {
        const { dispatch } = this.props;
        dispatch(setVideoIsPlaying(true));
    }

    onVideoPaused() {
        const { dispatch } = this.props;
        dispatch(setVideoIsPlaying(false));
    }

    onVideoStopped() {
        const { dispatch } = this.props;
        dispatch(setVideoIsPlaying(false));
    }

    onVideoEnded() {
        this.setState({
            hideControls: false,
            isMounted: false
        });
        this.clearHideControlsTimer();
        this.endedTimeout = setTimeout(() => {
            const { onVideoEnd } = this.props;
            this.onVideoStopped();
            if (onVideoEnd) {
                onVideoEnd();
            }
        }, this.props.endTimeoutInterval !== undefined ? this.props.endTimeoutInterval : 500);
    }

    componentDidMount() {
        if (this.state.data) {
            return;
        }
        const { uid } = this.props;
        if (!uid) {
            console.warn('No video data, and no UID in props :(');
            return;
        }
        api
            .get(`/articles/${uid}`, { params: { token: getPreviewToken() } })
            .then(({ data }) => this.setState({ data }))
            .catch(error => {
                console.error(error);
            });
    }

    componentWillUnmount() {
        this.clearHideControlsTimer();
        if (this.endedTimeout) {
            clearTimeout(this.endedTimeout);
            this.endedTimeout = null;
        }
        if (this.progressTween) {
            this.progressTween.kill();
            delete this.progressTween;
        }
        this.onVideoStopped();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { videoPlaying, videoEnded, progress } = this.state;
        if (videoPlaying !== prevState.videoPlaying) {
            if (videoPlaying) {
                this.onVideoPlaying();
                this.createHideControlsTimer();
            } else if (videoEnded) {
                this.onVideoEnded();
            }
        }
        if (videoPlaying && progress !== prevState.progress && this.progressRef.current) {
            if (this.progressTween) {
                this.progressTween.kill();
            }
            this.progressTween = TweenMax.to(this.progressRef.current, 0.5, { scaleX: progress, ease: 'Linear.easeNone' });
        }
    }

    render() {

        const { data, videoPlaying, videoEnded, hideControls, isMounted } = this.state;

        if (!data) {
            return null;
        }

        const { video, posterImage } = data;

        const { onVideoEnd, loopVideo } = this.props;

        const autoPlay = true;
        const loop = !onVideoEnd && loopVideo !== false;

        return (
            <Transition
                timeout={500}
                in={true}
                appear={true}
                onEntering={node => {
                    new TimelineMax()
                        .fromTo(node, 0.3, { opacity: 0.0001 }, { opacity: 1, ease: 'Cubic.easeIn' }, 0.2)
                        .fromTo(node, 0.5, { scaleX: 1.5, scaleY: 1.5 }, { scaleX: 1, scaleY: 1, ease: 'Quint.easeOut' }, 0.2)
                        .set(node, { clearProps: 'all' });
                }}
            >
                <div ref={this.ref} className="screen" style={{
                    backgroundColor: '#000F24'
                }}>
                    <div className="screen" style={{
                        display: 'flex',
                        background: posterImage && posterImage.lqip ? `url(${posterImage.lqip})` : '',
                        backgroundSize: 'cover',
                        transition: 'opacity 0.5s ease-out',
                        opacity: isMounted ? 1 : 0.0001
                    }} onClick={this.onClick}>
                        <div className="screen" style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            zIndex: 3,
                            pointerEvents: !videoPlaying ? 'none' : ''
                        }}>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                width: '100%',
                                position: 'absolute',
                                left: 0,
                                bottom: 0,
                                borderBottom: '9px solid #002066'
                            }}>
                                <div style={{
                                    width: 'calc(100% - 240px)',
                                    maxWidth: 1160
                                }}>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '100%',
                                        backgroundColor: 'rgba(13,44,109,0.9)',
                                        position: 'absolute',
                                        left: 0, bottom: 0,
                                        padding: '27px 60px 27px 50px',
                                        opacity: (hideControls || videoEnded) ? 0 : 1,
                                        transition: (hideControls || videoEnded) ? 'opacity 1s ease-out' : 'opacity 0.3s ease-out'
                                    }}>
                                        <div style={{ flex: '0 0 auto' }}>
                                            <button onClick={this.rewindVideo} style={{ marginRight: 10 }}>
                                                <RewindSvg style={{
                                                    flex: '0 0 auto',
                                                    width: 60,
                                                    height: 60
                                                }}/>
                                            </button>
                                            <button onClick={this.togglePause}>
                                                {this.state.videoPaused ? (
                                                    <PlaySvg style={{
                                                        flex: '0 0 auto',
                                                        width: 60,
                                                        height: 60
                                                    }}/>
                                                ) : (
                                                    <PauseSvg style={{
                                                        flex: '0 0 auto',
                                                        width: 60,
                                                        height: 60
                                                    }}/>
                                                )}
                                            </button>
                                        </div>
                                        {video && (video.caption || video.credit) ? (
                                            <div style={{ paddingLeft: 40, fontSize: 15, lineHeight: 1.6, flex: '1 1 auto' }}>
                                                {video.caption ? (<p>{video.caption}</p>) : null}
                                                {video.credit ? (<p>{video.credit}</p>) : null}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div ref={this.progressRef} style={{
                                        width: '100%',
                                        height: 9,
                                        position: 'absolute',
                                        bottom: -9,
                                        left: 0,
                                        transform: `scale(0, 1)`,
                                        transformOrigin: 'left center',
                                        backgroundColor: '#0ABBEF'
                                    }}/>
                                </div>
                            </div>
                        </div>
                        <div className="screen" style={{
                            zIndex: 2,
                            transition: 'opacity 0.5s ease-out',
                            opacity: videoPlaying ? 1 : 0.0001
                        }}>
                            <video ref={this.videoRef} loop={loop} style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover'
                            }} autoPlay={autoPlay} onPlay={() => {
                                this.setState({ videoPlaying: true, videoEnded: false, videoPaused: true });
                                this.createHideControlsTimer();
                            }} onEnded={() => {
                                this.setState({
                                    videoPlaying: false,
                                    videoEnded: true,
                                    hideControls: false
                                });
                            }} onPause={() => {
                                if (this.progressTween) {
                                    this.progressTween.kill();
                                    delete this.progressTween;
                                }
                                this.setState({ videoPaused: true });
                                this.onVideoPaused();
                            }} onTimeUpdate={e => {
                                const { current: video } = this.videoRef;
                                if (video && video.duration) {
                                    this.setState({ progress: video.currentTime / video.duration, videoPaused: false });
                                    this.onVideoPlaying();
                                }
                            }} onError={() => this.onVideoEnded()}>
                                {video && video.url ? (<source src={video.url}/>) : null}
                            </video>
                        </div>
                        {!autoPlay && posterImage && posterImage.url ? (
                            <div className="screen" style={{
                                background: posterImage.lqip ? `url(${posterImage.lqip})` : '',
                                backgroundSize: 'cover',
                                zIndex: 1
                            }}>
                                <LazyImage src={posterImage.url} style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover'
                                }}/>
                            </div>
                        ) : null}
                    </div>
                </div>
            </Transition>
        );
    }
}

export default connect()(Video);
