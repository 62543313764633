import axios from "axios";
import { stringify } from 'qs';

export const getPreviewToken = () => (document.location.href.match(/\btoken=([^&]+)/) || [])[1] || null;

export default axios.create({
    headers: {
        'Accept': 'application/json'
    },
    baseURL: '/api/',
    transformRequest: [(data) => stringify(data)]
});
