import React from 'react';

import { NavLink } from "react-router-dom";
import LazyImage from "../LazyImage";
import styles from "../../styles/station.module.scss";

import { COLUMN_WIDTH, GUTTER, ROW_HEIGHT } from "../Station";

const StationArticlePlug = ({ className, isLarge = false, ...data }) => {

    const image = data.image ? (isLarge ? data.image.large : data.image.small) : null;
    const displayText = isLarge || !image;

    return (
        <NavLink to={`/articles/${data.uid}`} className={className} style={{
            width: isLarge ? (COLUMN_WIDTH * 2) + (GUTTER * 2) : COLUMN_WIDTH,
            height: isLarge ? (ROW_HEIGHT * 2) + (GUTTER * 2) : ROW_HEIGHT,
            margin: GUTTER
        }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {image ? (
                    <div style={{
                        width: '100%',
                        height: isLarge ? (ROW_HEIGHT + (GUTTER * 2)) : 146,
                        flex: '0 0 auto',
                        position: 'relative',
                        background: `url(${image.lqip})`,
                        backgroundSize: 'cover',
                        overflow: 'hidden',
                        transform: 'translate3d(0, 0, 0)'
                    }}>
                        <LazyImage src={image.url} {...image} style={{
                            position: 'absolute',
                            top: 0, left: 0,
                            width: '100%', height: '100%',
                            objectFit: 'cover'
                        }}/>
                    </div>
                ) : null}
                <div style={{
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    padding: isLarge ? 30 : '20px 20px 25px',
                    flex: '1 1 auto',
                    backgroundColor: '#F7F6F7'
                }}>
                    <div style={{
                        position: 'relative',
                        width: '100%',
                        height: '100%',
                        overflow: 'hidden'
                    }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            position: 'absolute',
                            top: 0, left: 0,
                            width: '100%', height: '100%'
                        }}>
                            {data.strapline ? (
                                <p className={styles.strapline} style={{
                                    fontSize: 15,
                                    lineHeight: 1.2,
                                    marginBottom: 5
                                }}>{data.strapline}</p>
                            ) : null}
                            <h3 className={styles.heading} style={{
                                fontSize: isLarge ? 24 : 18,
                                lineHeight: 1.2,
                                marginBottom: isLarge ? 5 : 8
                            }} dangerouslySetInnerHTML={{ __html: data.title }}/>
                            {displayText && data.text ? (
                                <p className={styles.text} style={{
                                    fontSize: 15,
                                    lineHeight: 1.6
                                }} dangerouslySetInnerHTML={{ __html: data.text }}/>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </NavLink>
    );
};

export default StationArticlePlug;
