import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Transition, TransitionGroup } from "react-transition-group";

import Article from './Article';
import Images from './Images';
import Video from './Video';

import Modal from "./Modal";

import { setModal } from "../store";

require('gsap');
const TimelineMax = window.TimelineMax;

const renderModal = (modal, dispatch) => {

    if (!modal) {
        return null;
    }

    const { type } = modal;
    let Component;
    if (type === 'images') {
        Component = Images;
    } else if (type === 'video') {
        Component = Video;
        modal = {
            ...modal,
            onVideoEnd: () => dispatch(setModal(null))
        };
    } else if (type !== 'container') {
        Component = Article;
    }

    if (!Component) {
        console.warn(`Unknown modal type ${type}`);
        return null;
    }

    return (
        <Component {...modal}/>
    );
};

const ModalMount = ({ dispatch, history, modal }) => {

    // Make sure modals are removed when navigation occurs
    useEffect(() => {
        const listener = history.listen(() => {
            dispatch(setModal(null));
        });
        return () => {
            listener();
        };
    }, [dispatch, history]);

    return (
        <TransitionGroup component={null}>
            <Transition
                in={!!modal}
                appear={true}
                timeout={200}
                key={modal ? modal.uid : "modal"}
                onEntering={node => {
                    if (!node) {
                        return;
                    }
                    new TimelineMax()
                        .fromTo(node, 0.25, { opacity: 0.0001 }, {
                            opacity: 1,
                            ease: 'Linear.easeNone'
                        });
                }}
                onExiting={node => {
                    if (!node) {
                        return;
                    }
                    new TimelineMax()
                        .to(node, 0.2, { opacity: 0.0001, ease: 'Linear.easeNone' });
                }}
            >
                <Modal type={modal ? modal.type || null : null}>
                    {renderModal(modal, dispatch)}
                </Modal>
            </Transition>
        </TransitionGroup>
    );
};

export default withRouter(connect(state => ({
    modal: state.modal
}))(ModalMount));
