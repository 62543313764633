import React from 'react';
import Flickity from 'react-flickity-component';
import { Transition } from "react-transition-group";
import classNames from 'classnames';
import api, { getPreviewToken } from "../api";
import LazyImage from "./LazyImage";
import styles from '../styles/images.module.scss';

import { ReactComponent as ArrowSvg } from "../img/btn-back.svg";

require('gsap');
const TimelineMax = window.TimelineMax;

class Images extends React.Component {

    constructor(props) {
        super(props);
        this.flickity = null;
        this.onResize = this.onResize.bind(this);
        this.onClick = this.onClick.bind(this);
        this.state = {
            data: props.data || null,
            currentImage: (props.startAt || 0) + 1
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.onResize);
        if (this.state.data) {
            return;
        }
        const { uid } = this.props;
        api
            .get(`/articles/${uid}`, { params: { token: getPreviewToken() } })
            .then(({ data }) => this.setState({ data }))
            .catch(error => {
                console.error(error);
            });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    onResize() {
        const { slides = [] } = this.flickity || {};
        if (!slides.length) {
            return;
        }
        // Scale images
        slides.forEach(slide => {
            const { element } = slide.cells[0];
            if (!element) {
                return;
            }
            const img = element.querySelector('img');
            const figure = element.querySelector('figure');
            figure.style.width = '';
            img.style.width = '';
            img.style.height = '';
            const wrapperRect = element.querySelector('div').getBoundingClientRect();
            const caption = element.querySelector('figcaption');
            const wrapperWidth = wrapperRect.width;
            const imgNaturalWidth = parseInt(img.getAttribute('width'), 10);
            const imgScale = wrapperWidth / imgNaturalWidth;
            const imgWidth = imgNaturalWidth * imgScale;
            const imageNaturalHeight = parseInt(img.getAttribute('height'), 10);
            const imgHeight = imageNaturalHeight * imgScale;
            const availableHeight = Math.round(wrapperRect.height - (caption ? caption.getBoundingClientRect().height : 0));
            if (imgHeight > availableHeight) {
                const imgRatio = imgWidth / imgHeight;
                figure.style.width = `${Math.round(availableHeight * imgRatio)}px`;
                img.style.width = `${Math.round(availableHeight * imgRatio)}px`;
                img.style.height = `${availableHeight}px`;
            }
        });
    }

    onClick(e) {
        if (!this.flickity) {
            return;
        }
        const { clientX } = e;
        const stageW = window.innerWidth;
        if (clientX <= stageW / 2) {
            this.flickity.previous();
        } else {
            this.flickity.next();
        }
    }

    render() {

        if (!this.state.data) {
            return null;
        }

        const { images } = this.state.data;

        const flickityOptions = {
            adaptiveHeight: false,
            pageDots: false,
            prevNextButtons: false,
            wrapAround: true,
            imagesLoaded: true,
            initialIndex: this.props.startAt || 0,
            on: {
                ready: this.onResize,
                select: () => {
                    this.setState({ currentImage: this.flickity.selectedIndex + 1 });
                }
            }
        };

        return (
            <Transition
                timeout={500}
                in={true}
                appear={true}
                onEntering={node => {
                    new TimelineMax()
                        .fromTo(node, 0.3, { opacity: 0.0001 }, { opacity: 1, ease: 'Cubic.easeIn' }, 0.2)
                        //.fromTo(node, 0.5, { scaleX: 1.2, scaleY: 1.2 }, { scaleX: 1, scaleY: 1, ease: 'Quint.easeOut' }, 0.2)
                        .set(node, { clearProps: 'all' });
                }}
            >
                <div className={classNames('screen', styles.root)} style={{
                    backgroundColor: '#000F24',
                    zIndex: 1
                }}>
                    {images.length > 1 ? (
                        <p style={{
                            position: 'absolute',
                            top: 65,
                            right: 180,
                            textAlign: 'right',
                            color: '#0ABBEF',
                            fontSize: 30,
                            lineHeight: 1.2,
                            zIndex: 3,
                            pointerEvents: 'none'
                        }}>{this.state.currentImage}/{images.length}</p>
                    ) : null}
                    {images.length > 1 ? (
                        <div className={styles.prevnextbtn} style={{ left: 0, zIndex: 2 }}>
                            <button className="clickable"  onClick={this.onClick}>
                                <ArrowSvg style={{
                                    width: 25,
                                    height: 43,
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    margin: `-20px 0 0 -13px`
                                }}/>
                            </button>
                        </div>
                    ) : null}
                    <div className={styles.slides} style={{ zIndex: 1 }}>
                        <Flickity options={flickityOptions} style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%'
                        }} flickityRef={ref => {
                            this.flickity = ref;
                            this.onResize();
                        }}>
                            {(images || []).map(image => (
                                <div key={image.uid} className={styles.slide} data-slide>
                                    <div>
                                        <figure>
                                            <div style={{
                                                background: `url(${image.lqip})`,
                                                backgroundSize: 'cover',
                                                overflow: 'hidden'
                                            }}>
                                                <LazyImage src={image.url} {...image} />
                                            </div>
                                            {image.caption || image.credit ? (
                                                <figcaption>
                                                    {image.caption ? (
                                                        <p>{image.caption}</p>
                                                    ) : null}
                                                    {image.credit ? (
                                                        <p>{image.credit}</p>
                                                    ) : null}
                                                </figcaption>
                                            ) : null}
                                        </figure>
                                    </div>
                                </div>
                            ))}
                        </Flickity>
                    </div>
                    {images.length > 1 ? (
                        <div className={styles.prevnextbtn} style={{ right: 0, zIndex: 2 }}>
                            <button className="clickable" onClick={this.onClick}>
                                <ArrowSvg style={{
                                    width: 25,
                                    height: 43,
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    margin: `-20px 0 0 -13px`,
                                    transform: 'scale(-1, 1)'
                                }}/>
                            </button>
                        </div>
                    ) : null}
                </div>
            </Transition>
        );
    }

}

export default Images;
