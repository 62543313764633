import React from 'react';
import LazyImage from "./LazyImage";

import { ReactComponent as FullscreenBtnSvg } from '../img/btn-fullscreen.svg';

const ArticleContentImage = ({ image, caption }) => (
    <figure style={{ marginBottom: 40, position: 'relative' }}>
        <div style={{ position: 'relative' }}>
            <div style={{
                display: 'block',
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0
            }}>
                <FullscreenBtnSvg style={{
                    width: 40,
                    height: 40,
                    position: 'absolute',
                    top: 20,
                    right: 20,
                    zIndex: 2
                }}/>
            </div>
            <div style={{
                background: `url(${image.lqip})`,
                backgroundSize: 'cover',
                position: 'relative',
                zIndex: 1
            }}>
                <LazyImage src={image.url} {...image} />
            </div>
        </div>
        {caption || image.credit ? (
            <figcaption style={{
                fontSize: 13,
                lineHeight: 1.6,
                fontStyle: 'italic',
                paddingTop: 10
            }}>
                {caption ? (
                    <p>{caption}</p>
                ) : null}
                {image.credit ? (
                    <p>{image.credit}</p>
                ) : null}
            </figcaption>
        ) : null}
    </figure>
);

export default ArticleContentImage;
