export const getQueryParam = (param) => {
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split("=");
        if (pair[0] === param) {
            return pair[1] || null;
        }
    }
    return null;
};

export const truncateText = (text, maxLength, suffix = '...') => {
    text = text.trim();
    if (text.length < maxLength - suffix.length) {
        return text;
    }
    return text.substr(0, maxLength - suffix.length).trim() + suffix;
};
