import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { connect } from 'react-redux';

import axios from 'axios';
import api from "./api";

import { setSettings } from './store';

import './app.scss';

import WelcomeScreen from "./components/WelcomeScreen";
import WelcomeScreenOffice from "./components/WelcomeScreenOffice";
import SegmentsIndex from "./components/segments/SegmentsIndex";
import SegmentsEntry from "./components/segments/SegmentsEntry";
import ContentScreens from "./components/ContentScreens";
import VideoJukeboxesScreen from "./components/VideoJukeboxesScreen";

const App = ({ dispatch }) => {

    // Get settings (and get them again, and again...)
    useEffect(() => {
        let refresh;
        let cancelTokenSource;
        const getSettings = () => {
            cancelTokenSource = axios.CancelToken.source();
            api
                .get(`settings`, { cancelToken: cancelTokenSource.token })
                .then(({ data }) => {
                    dispatch(setSettings(data));
                })
                .catch(error => {
                    if (axios.isCancel(error)) {
                        return;
                    }
                    console.error(error);
                })
                .then(() => {
                    cancelTokenSource = null;
                    if (refresh) {
                        clearTimeout(refresh);
                    }
                    refresh = setTimeout(getSettings, 60000); // Once every minute
                });
        };
        getSettings();
        return () => {
            if (refresh) {
                clearTimeout(refresh);
                refresh = null;
            }
            if (cancelTokenSource) {
                cancelTokenSource.cancel();
            }
        };
    }, [dispatch]);

    return (
        <Router>
            <Switch>
                <Route exact path="/welcome" component={WelcomeScreen}/>
                <Route exact path="/welcome/:uid" component={WelcomeScreenOffice}/>
                <Route exact path="/segments" component={SegmentsIndex}/>
                <Route exact path="/segments/:uid" component={SegmentsEntry}/>
                <Route path="/(video-jukeboxes)/" render={({ location }) => (
                    <VideoJukeboxesScreen location={location}/>
                )}/>
                <Route render={({ location }) => (
                    <ContentScreens location={location}/>
                )}/>
            </Switch>
        </Router>
    );
};

export default connect()(App);
