import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import LazyImage from "../LazyImage";

import get from "lodash/get";

const WelcomeScreenGallery = ({ images4k: images, style = {}, loop = true, officeInterval, officeImageInterval }) => {

    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {

        if (officeInterval === null || officeImageInterval === null) {
            return;
        }

        let interval = officeImageInterval;
        if (interval * images.length < officeInterval) {
            interval = officeInterval / images.length;
        }
        const timeout = setTimeout(() => {
            if (currentImageIndex >= images.length - 1 && !loop) {
                return;
            }
            setCurrentImageIndex(currentImageIndex < images.length - 1 ? currentImageIndex + 1 : 0);
        }, interval);
        return () => {
            clearTimeout(timeout);
        };
    }, [images, currentImageIndex, loop, officeInterval, officeImageInterval]);

    return (
        <div style={{
            width: '100%',
            height: '100%',
            backgroundColor: '#0D2C6D',
            position: 'absolute',
            top: 0, left: 0,
            ...style
        }}>
            {(images || []).map((image, index) => (
                <div key={image.uid} style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    opacity: index === currentImageIndex ? 1 : 0,
                    transition: 'opacity 0.5s ease-in-out'
                }}>
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        overflow: 'hidden'
                    }}>
                        <LazyImage src={image.url} {...image} style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover'
                        }}/>
                    </div>
                    {image.credit ? (
                        <p style={{
                            position: 'absolute',
                            top: 'calc(100% - 10px)',
                            right: 5,
                            textAlign: 'right',
                            color: '#ffffff',
                            display: 'block',
                            padding: '40px 40px 0 0',
                            fontStyle: 'italic',
                            fontSize: 24
                        }}>Photo: {image.credit}</p>
                    ) : null}
                </div>
            ))}
        </div>
    );
};

export default connect(state => ({
    officeInterval: get(state, 'settings.welcomeScreenOfficeInterval', null),
    officeImageInterval: get(state, 'settings.welcomeScreenOfficeImageSlideshowInterval', null)
}))(WelcomeScreenGallery);
