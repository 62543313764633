import React, { useEffect, useState } from 'react';
import get from 'lodash/get';

import api, { getPreviewToken } from '../../api';
import TextArticle from "../articles/TextArticle";

const OfficeArticle = props => {

    const [data, setData] = useState(null);

    const uid = get(props, 'match.params.uid');

    useEffect(() => {
        api
            .get(`offices/${uid}`, { params: { token: getPreviewToken() } })
            .then(({ data }) => setData(data))
            .catch(error => {
                console.error(error);
            });
    }, [uid]);

    if (!data) {
        return null;
    }

    return (
        <TextArticle status={props.status || null} data={data}/>
    );
};

export default OfficeArticle;
