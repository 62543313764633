import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import { makeStore } from './store';
import * as serviceWorker from './serviceWorker';

import Hammer from 'hammerjs';

require('lazysizes');

// Reload on uncaught errors (after a small delay)
// window.onerror = function() {
//     setTimeout(() => {
//         window.location.reload();
//     }, 3000);
// };

// Reload to root when the user hits the ESC key
window.addEventListener('keyup', e => {
    const key = e.which || e.keyCode || null;
    if (key === 27) { // ESC
        window.location.href = '/';
    }
});

// Use Hammer to prevent pinch events, in order to prevent in-browser zooming on touch devices
const root = document.getElementById('root');
const hammer = Hammer(root);
hammer.get('pinch').set({ enable: true });

ReactDOM.render(
    <Provider store={makeStore()}>
        <App/>
    </Provider>,
    root
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
