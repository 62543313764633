import React from 'react';
import { Transition, TransitionGroup } from "react-transition-group";
import { connect } from 'react-redux';
import get from 'lodash/get';
import find from 'lodash/find';
import axios from 'axios';
import api, { getPreviewToken } from '../../api';
import Video from "../Video";
import LazyImage from "../LazyImage";
import ClickToStartScreen from "../ClickToStartScreen";

require('gsap');
const TweenMax = window.TweenMax;

class SegmentsEntry extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: null,
            currentSegmentUid: null
        };
        this.uid = props.uid || get(props, 'match.params.uid');
        this.cancelAjaxTokenSource = null;
        this.fetchDataTimer = null;
        this.nextSegmentTimer = null;
        this.nextSegmentPlease = this.nextSegmentPlease.bind(this);
    }

    nextSegmentPlease() {
        console.log('show the next segment');
        if (this.nextSegmentTimer) {
            clearTimeout(this.nextSegmentTimer);
            this.nextSegmentTimer = null;
        }
        const { currentSegmentUid, data } = this.state;
        const segments = get(data, 'segments', []);
        const segmentUids = segments.map(segment => segment.uid);
        const currentSegmentUidIndex = segmentUids.indexOf(currentSegmentUid);
        const nextSegmentUidIndex = currentSegmentUidIndex < segmentUids.length - 1 ? currentSegmentUidIndex + 1 : 0;
        const nextSegmentUid = segmentUids[nextSegmentUidIndex] || segmentUids[0];
        console.log({ nextSegmentUid });
        this.setState({
            currentSegmentUid: nextSegmentUid
        }, () => {
            const { defaultSegmentImageDuration } = this.props;
            const segmentData = find(segments, { uid: this.state.currentSegmentUid }, {});
            const { type, duration } = segmentData;
            if (type === 'video') {
                return;
            }
            const interval = (duration || defaultSegmentImageDuration) * 1000;
            console.log({ duration, defaultSegmentImageDuration, interval });
            this.nextSegmentTimer = setTimeout(() => {
                this.nextSegmentPlease();
            }, interval);
            console.log(`wait ${interval} ms for the next segment....`);
        });
    }

    fetchData() {
        if (this.fetchDataTimer) {
            clearTimeout(this.fetchDataTimer);
            this.fetchDataTimer = null;
        }
        if (this.cancelAjaxTokenSource) {
            this.cancelAjaxTokenSource.cancel();
            this.cancelAjaxTokenSource = null;
        }
        if (this.props.defaultSegmentImageDuration === null) {
            return null;
        }
        this.cancelAjaxTokenSource = axios.CancelToken.source();
        api
            .get(`segments/${this.uid}`, {
                params: { token: getPreviewToken() },
                cancelToken: this.cancelAjaxTokenSource.token
            })
            .then(({ data: newData }) => {
                this.setState({
                    data: newData
                }, () => {
                    if (!this.state.currentSegmentUid) {
                        this.nextSegmentPlease();
                    }
                });
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    return;
                }
                console.error(error);
            })
            .then(() => {
                this.cancelAjaxTokenSource = null;
                // Do it again in 30 secs, make sure the data is fresh
                this.fetchDataTimer = setTimeout(() => {
                    this.fetchData();
                }, 30000);
            });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.defaultSegmentImageDuration === null && this.props.defaultSegmentImageDuration !== null) {
            this.fetchData();
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    componentWillUnmount() {
        if (this.cancelAjaxTokenSource) {
            this.cancelAjaxTokenSource.cancel();
        }
        if (this.fetchDataTimer) {
            clearTimeout(this.fetchDataTimer);
        }
        if (this.nextSegmentTimer) {
            clearTimeout(this.nextSegmentTimer);
        }
    }

    render() {

        const { userHasInteractedWithScreen, defaultSegmentImageDuration } = this.props;

        if (defaultSegmentImageDuration === null) {
            // This means the settings hasn't loaded, so hold off
            return null;
        }

        if (!userHasInteractedWithScreen) {
            return (
                <ClickToStartScreen/>
            );
        }

        const segments = get(this.state, 'data.segments', []);
        const numSegments = segments.length;
        if (!numSegments) {
            return null;
        }

        const currentSegment = find(segments, { uid: this.state.currentSegmentUid });
        if (!currentSegment) {
            return null;
        }

        console.log('render current segment', { currentSegment });

        return (
            <div className="screen">
                <TransitionGroup component={null}>
                    <Transition
                        key={currentSegment.uid}
                        timeout={500}
                        appear={true}
                        onEntering={node => {
                            TweenMax.fromTo(node, 0.5, { opacity: 0.0001 }, { opacity: 0.9999 }, 0);
                            if (currentSegment.type === 'image') {
                                TweenMax.to(node, 60, { scaleX: 1.5, scaleY: 1.5, ease: 'Linear.easeNone' });
                            }
                        }}
                        onExiting={node => {
                            TweenMax.to(node, 0.5, { opacity: 0 });
                        }}
                        onExited={node => {
                            TweenMax.set(node, { clearProps: 'true' });
                            TweenMax.killTweensOf(node);
                        }}
                    >
                        <div className="screen" style={{ backgroundColor: '#031D5A' }}>
                            {currentSegment.type === 'video' ? (
                                <Video data={currentSegment}
                                       loopVideo={numSegments <= 1}
                                       onVideoEnd={numSegments > 1 ? () => {
                                           this.nextSegmentPlease();
                                       } : null}
                                       endTimeoutInterval={0}
                                />
                            ) : (
                                <div className="screen" style={{
                                    overflow: 'hidden',
                                    backgroundImage: `url(${currentSegment.image.lqip})`,
                                    backgroundSize: 'cover'
                                }}>
                                    <LazyImage src={currentSegment.image.url} alt="" style={{
                                        width: '100%',
                                        height: '100%',
                                        position: 'absolute',
                                        top: 0, left: 0,
                                        objectFit: 'cover',
                                        objectPosition: `${currentSegment.image.focalPoint.x * 100}% ${currentSegment.image.focalPoint.y * 100}%`
                                    }}/>
                                </div>
                            )}
                        </div>
                    </Transition>
                </TransitionGroup>
            </div>
        );
    }

}

export default connect(state => ({
    defaultSegmentImageDuration: get(state, 'settings.defaultSegmentImageDuration', null),
    userHasInteractedWithScreen: state.userHasInteractedWithScreen
}))(SegmentsEntry);
