import React from 'react';
import { connect } from 'react-redux';
import { setUserHasInteractedWithScreen } from "../store";

/**
 *
 * @param dispatch
 * @returns {JSX.Element}
 * @constructor
 */
const ClickToStartScreen = ({ dispatch }) => (
    <div className="screen flex" onClick={() => dispatch(setUserHasInteractedWithScreen(true))}>
        <p style={{
            fontWeight: 'bold',
            fontSize: 36
        }}>Click to start</p>
    </div>
);

export default connect()(ClickToStartScreen);
