import React, { useEffect, useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { Transition, TransitionGroup } from "react-transition-group";
import get from "lodash/get";
import api, { getPreviewToken } from "../api";

import WelcomeScreenWeather from "./welcome/WelcomeScreenWeather";
import WelcomeScreenGallery from "./welcome/WelcomeScreenGallery";
import WelcomeScreenInfo from "./welcome/WelcomeScreenInfo";
import WelcomeScreenHeader from "./welcome/WelcomeScreenHeader";
import WelcomeScreenMap from "./welcome/WelcomeScreenMap";
import WelcomeScreenFooter from "./welcome/WelcomeScreenFooter";
import WelcomeScreenLocalTime from "./welcome/WelcomeScreenLocalTime";

const WelcomeScreenOffice = ({ data: dataFromProps, loopGallery = true, ...props }) => {

    const [data, setData] = useState(dataFromProps);
    const [scale, setScale] = useState(1);

    useEffect(() => {
        if (data) {
            return;
        }
        const uidFromUrl = get(props, 'match.params.uid');
        if (!uidFromUrl) {
            console.error('no uid in URL, and no data :/');
            return;
        }
        api
            .get(`offices/${uidFromUrl}`, { params: { token: getPreviewToken() } })
            .then(({ data }) => setData(data))
            .catch(error => {
                console.error(error);
            });
    }, [props, data]);

    const resizeHandler = useCallback(() => {
        const winWidth = window.innerWidth;
        if (winWidth < 3840) {
            setScale(winWidth / 3840);
        } else {
            setScale(1);
        }
    }, []);

    useEffect(() => {
        window.addEventListener('resize', resizeHandler);
        resizeHandler();
        return () => {
            window.removeEventListener('resize', resizeHandler);
        };
    }, [resizeHandler]);

    useEffect(() => {
        setData(dataFromProps);
    }, [dataFromProps]);

    if (!data) {
        return null;
    }

    return (
        <div className="screen" style={{
            width: 3840,
            height: 2160,
            backgroundImage: 'linear-gradient(180deg, #0F2D6D 14%, #0ABBEF 100%)',
            transform: `scale(${scale}, ${scale})`,
            transformOrigin: 'left top'
        }}>
            <div style={{ display: 'flex', width: '100%', height: '100%', position: 'absolute' }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '58%',
                    height: '100%',
                    maxWidth: 2265,
                    flex: '0 0 auto',
                    position: 'relative',
                    backgroundColor: '#0D2C6D'
                }}>
                    <div style={{
                        width: '100%',
                        flex: '1 1 auto',
                        backgroundColor: '#0D2C6D',
                        position: 'relative'
                    }}>
                        <TransitionGroup component={null}>
                            <Transition key={`gallery-${data.uid}`} timeout={500}>
                                {status => (
                                    <WelcomeScreenGallery loop={loopGallery} {...data} style={{
                                        opacity: ['entering', 'entered'].indexOf(status) > -1 ? 1 : 0,
                                        transition: 'opacity 0.5s ease-in-out'
                                    }}/>
                                )}
                            </Transition>
                        </TransitionGroup>
                    </div>
                    <WelcomeScreenInfo {...data}/>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '42%',
                    height: '100%',
                    flex: '1 1 auto',
                    padding: 60,
                    position: 'relative'
                }}>
                    {data.timezone ? (
                        <div style={{ position: 'absolute', top: 80, right: 80 }}>
                            <WelcomeScreenLocalTime {...data}/>
                        </div>
                    ) : null}
                    <div style={{
                        width: '100%',
                        position: 'absolute',
                        left: 0,
                        top: 0
                    }}>
                        <WelcomeScreenHeader {...data}/>
                    </div>
                    <div style={{
                        width: '100%',
                        position: 'absolute',
                        top: 600, left: 0
                    }}>
                        <WelcomeScreenWeather {...data}/>
                    </div>
                    <div style={{
                        width: '100%',
                        left: 0, top: 1000,
                        position: 'absolute'
                    }}>
                        <WelcomeScreenMap {...data}/>
                    </div>
                    <WelcomeScreenFooter {...data}/>
                </div>
            </div>
        </div>
    );
};

export default withRouter(WelcomeScreenOffice);
