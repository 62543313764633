import React from 'react';
import ArticleCard from "./ArticleCard";

const RelatedArticles = ({ articles }) => (
    <>
        <div className="wysiwyg">
            <h3 style={{ marginBottom: 28 }}>You might also like</h3>
        </div>
        <ul style={{ width: '100%' }}>
            {articles.map(article => (
                <li key={article.uid} style={{ width: '100%', marginBottom: 14 }}>
                    <ArticleCard {...article}/>
                </li>
            ))}
        </ul>
    </>
);

export default RelatedArticles;
