import React from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';

import { setModal } from "../store";

import { ReactComponent as CloseSvg } from "../img/btn-close.svg";

const Modal = ({ dispatch, type, children }) => children ? createPortal((
    <div className="screen" role="dialog" style={{
        zIndex: 999,
        backgroundColor: '#000F24'
    }}>
        {/* Menu toggle */}
        <button className="clickable" style={Object.assign({
            position: 'fixed',
            top: 40,
            right: 50,
            width: 80,
            height: 80,
            zIndex: 2
        }, type === 'video' ? {
            borderRadius: '100%',
            backgroundColor: '#052362'
        } : {})} onClick={() => dispatch(setModal(null))}>
            <CloseSvg style={{
                width: 60,
                height: 60,
                position: 'absolute',
                top: '50%',
                left: '50%',
                margin: '-30px 0 0 -30px',
                zIndex: 2
            }}/>
        </button>
        <div className="screen flex" style={{
            position: 'relative',
            zIndex: 1
        }}>
            {children}
        </div>
    </div>
), document.body) : null;

export default connect()(Modal);
