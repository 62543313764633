import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';

import { ReactComponent as ClickTipSvg } from '../img/hand-click.svg';
import { ReactComponent as SwipeTipSvg } from '../img/hand-swipe.svg';

const ClickSwipeTip = ({ type, swipeText, clickText, style = {} }) => {

    const svgStyle = {
        width: 105,
        height: 136,
        marginTop: -12,
        marginBottom: 15
    };

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: 258,
            height: 258,
            borderRadius: '100%',
            overflow: 'hidden',
            backgroundColor: '#002066',
            color: '#fff',
            fontSize: 15,
            lineHeight: 1.2,
            textAlign: 'center',
            padding: 30,
            ...style
        }}>
            {type === 'click' ? (
                <ClickTipSvg style={svgStyle}/>
            ) : (
                <SwipeTipSvg style={svgStyle}/>
            )}
            <p dangerouslySetInnerHTML={{ __html: type === 'click' ? clickText : swipeText }}/>
        </div>
    );
};

export default connect(state => ({
    swipeText: get(state, 'settings.text.swipeTimelineHelperText', ''),
    clickText: get(state, 'settings.text.clickTimelineDotHelperText', '')
}))(ClickSwipeTip);
